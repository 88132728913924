import { ServiceBase, IResponse } from './ServiceBase';
import { IMarketingInfoResponseModel, IMarketingInfoRequestModel } from '../models/marketingInfo';
import { IPhotoResponseModel } from '../models/projectPhoto';

export default class MarketingInfoService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number): Promise<IMarketingInfoResponseModel> {
    const res = await this.get<IResponse<IMarketingInfoResponseModel>>(`/${id}/MarketingInformation`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async save(projectId: number, data: IMarketingInfoRequestModel): Promise<IMarketingInfoResponseModel> {
    const res = await this.put<IResponse<IMarketingInfoResponseModel>>(`/${projectId}/MarketingInformation`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async addPhoto(projectId: number, data: FormData, tabName?: string): Promise<IPhotoResponseModel> {
    let url = `/${projectId}/Photo`;
    if (tabName) {
      url += `/?tabName=${tabName}`;
    }
    const res = await this.post<IResponse<IPhotoResponseModel>>(url, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
