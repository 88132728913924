import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import DocumentService from '../services/DocumentService';
import { IDocumentResponseModel } from '../models/document';

export enum DocumentActionTypes {
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS',
}
export interface IDocumentAction extends Action {
  type: DocumentActionTypes;
  payload?: IDocumentResponseModel[];
}
export const setDocumentsAction = (payload: IDocumentResponseModel[]): IDocumentAction => ({
  type: DocumentActionTypes.SET_DOCUMENTS,
  payload,
});

export const cleatAction = (): IDocumentAction => ({
  type: DocumentActionTypes.CLEAR_DOCUMENTS,
});
export const clearDocuments = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const loadDocuments = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await DocumentService.getByProjectId(projectId);
    dispatch(setDocumentsAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
