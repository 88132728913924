exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf"));

// Module
exports.push([module.id, "\r\n@font-face {\r\n    font-family: \"IBMPlexSans-Regular\";\r\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"truetype\");\r\n    font-style: normal;\r\n    font-weight: normal;\r\n}\r\n@font-face {\r\n    font-family: \"IBMPlexSans-Medium\";\r\n    src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"truetype\");\r\n    font-style: normal;\r\n    font-weight: normal;\r\n}\r\n@font-face {\r\n    font-family: \"IBMPlexSans-Light\";\r\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"truetype\");\r\n    font-style: normal;\r\n    font-weight: normal;\r\n}\r\n@font-face {\r\n    font-family: \"IBMPlexSans-Bold\";\r\n    src: url(" + ___CSS_LOADER_URL___3___ + ") format(\"truetype\");\r\n    font-style: normal;\r\n    font-weight: normal;\r\n}\r\n", ""]);

