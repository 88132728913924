import { Reducer } from 'redux';
import { IProjectAction, ProjectActionTypes } from '../actions/ProjectAction';
import { ProjectFilter } from '../constants/projectFilter';
import { IProjectModel } from '../models/project';
import { IUserModel } from '../models/account';

export interface IProjectState {
  projects: IProjectModel[];
  filter: string;
  selectedProject: IProjectModel | null;
  selectedProjectId: number;
  indicatorVersionId: number;
  financialVersionId: number;
  statusVersionId: number;
  salesVersionId: number;
  selectedStages?: string[];
}

const initialState: IProjectState = {
  projects: [],
  indicatorVersionId: 0,
  financialVersionId: 0,
  statusVersionId: 0,
  salesVersionId: 0,
  filter: ProjectFilter.ALL,
  selectedProject: null,
  selectedProjectId: 0,
  selectedStages: [],
};
export const ProjectReducer: Reducer<IProjectState, IProjectAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case ProjectActionTypes.CHANGE_PROJECT_MANAGER: {
      const user = payload as IUserModel;
      return {
        ...state,
        selectedProject:
          state.selectedProject !== null
            ? { ...state.selectedProject, ownerName: user.fullName, ownerId: user.id }
            : null,
      };
    }
    case ProjectActionTypes.CHANGE_PROJECT_NAME: {
      return {
        ...state,
        selectedProject: state.selectedProject !== null ? { ...state.selectedProject, name: payload as string } : null,
      };
    }
    case ProjectActionTypes.CLEAR_ALL_PROJECTS_DATA:
      return initialState;
    case ProjectActionTypes.ADD_STAGE_FILTER: {
      const stages = state.selectedStages || [];
      return {
        ...state,
        selectedStages: [...stages, payload as string],
      };
    }
    case ProjectActionTypes.REMOVE_STAGE_FILTER: {
      const stages = state.selectedStages || [];
      return {
        ...state,
        selectedStages: stages.filter(s => s !== (payload as string)),
      };
    }
    case ProjectActionTypes.CLEAR_STAGE_FILTER: {
      return {
        ...state,
        selectedStages: [],
      };
    }
    case ProjectActionTypes.SET_INDICATOR_VERSIONID:
      return {
        ...state,
        indicatorVersionId: payload as number,
      };
    case ProjectActionTypes.SET_FINANCIAL_VERSIONID:
      return {
        ...state,
        financialVersionId: payload as number,
      };
    case ProjectActionTypes.SET_SALES_VERSIONID:
      return {
        ...state,
        salesVersionId: payload as number,
      };
    case ProjectActionTypes.SET_STATUS_VERSIONID:
      return {
        ...state,
        statusVersionId: payload as number,
      };
    case ProjectActionTypes.SET_SELECTED_PROJECT_ID:
      return {
        ...state,
        selectedProjectId: payload as number,
      };
    case ProjectActionTypes.SET_FILTER:
      return {
        ...state,
        filter: payload as string,
      };
    case ProjectActionTypes.SET_PROJECTS:
      return {
        ...state,
        projects: payload as IProjectModel[],
        errors: [],
      };
    case ProjectActionTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: payload as IProjectModel,
      };
    default:
      return state;
  }
};
