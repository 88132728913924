import { ServiceBase, IResponse } from './ServiceBase';
import { ITemplateResponse, ITemplateRequest } from '../models/template';

export class TemplateService extends ServiceBase {
  protected static BASE_URL = '/Admin';

  public static async getList(): Promise<ITemplateResponse[]> {
    const res = await this.get<IResponse<ITemplateResponse[]>>(`/Templates`);

    if (!res.data.success) {
      throw new Error(res.data.error);
    }
    return res.data.data;
  }

  public static async getById(id: number): Promise<ITemplateResponse> {
    const res = await this.get<IResponse<ITemplateResponse>>(`/Templates/${id}`);

    if (!res.data.success) {
      throw new Error(res.data.error);
    }
    return res.data.data;
  }

  public static async deleteTemplate(id: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/Templates/${id}`);
    if (!res.data.success) {
      throw new Error(res.data.error);
    }
    return res.data.data;
  }

  public static async add(data: ITemplateRequest): Promise<ITemplateResponse> {
    const res = await this.post<IResponse<ITemplateResponse>>(`/Templates`, data);
    if (!res.data.success) {
      throw new Error(res.data.error);
    }
    return res.data.data;
  }

  public static async update(data: ITemplateRequest, id: number): Promise<ITemplateResponse> {
    const res = await this.put<IResponse<ITemplateResponse>>(`/Templates/${id}`, data);
    if (!res.data.success) {
      throw new Error(res.data.error);
    }
    return res.data.data;
  }
}

export default TemplateService;
