import { Reducer } from 'redux';
import { IParticipantAction, ParticipantActionTypes } from '../actions/ParticipantAction';
import { IParticipantModel } from '../models/participant';

export interface IParticipantState {
  participants: IParticipantModel[];
}

const initialState: IParticipantState = {
  participants: [],
};
export const ParticipantReducer: Reducer<IParticipantState, IParticipantAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case ParticipantActionTypes.UPDATE_PARTICIPANT: {
      const data = payload as IParticipantModel;
      return {
        ...state,
        participants: state.participants.map(x => (x.id !== data.id ? x : data)),
      };
    }
    case ParticipantActionTypes.DELETE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.filter(x => x.id !== (payload as number)),
      };
    case ParticipantActionTypes.ADD_PARTICIPANT:
      return {
        ...state,
        participants: [...state.participants, payload as IParticipantModel],
      };
    case ParticipantActionTypes.SET_PARTICIPANTS:
      return {
        ...state,
        participants: payload as IParticipantModel[],
      };
    default:
      return state;
  }
};
