import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import PhotoAlbumService from '../services/PhotoAlbumService';
import { IPhotoAlbumResponseModel, IPhotoAlbumRequestModel } from '../models/photoAlbum';
import { IPhotoResponseModel } from '../models/projectPhoto';
import PhotoService from '../services/PhotoService';

export enum PhotoAlbumActionTypes {
  SET_PHOTOALBUMS = 'SET_PHOTOALBUMS',
  SELECT_PHOTOALBUM = 'SELECT_PHOTOALNUM',
  ADD_PHOTOALBUM = 'ADD_PHOTOALBUM',
  DELETE_PHOTOALBUM = 'DELETE_PHOTOALBUM',
  DELETE_PHOTO = 'DELETE_PHOTO',
  ADD_PHOTO = 'ADD_PHOTO',
  SELECT_PHOTO = 'SELECT_PHOTO',
  CLEAR_SELECTION = 'CLEAR_SELECTION',
  CLEAR_PHOTOABUMS = 'CLEAR_PHOTOABUMS',
}
export interface IPhotoAlbumAction extends Action {
  type: PhotoAlbumActionTypes;
  payload?: IPhotoAlbumResponseModel[] | IPhotoAlbumResponseModel | IPhotoResponseModel | number;
}
export const clearPhotoAlbumsAction = (): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.CLEAR_PHOTOABUMS,
});
export const clearPhotoAlbums = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearPhotoAlbumsAction());
  return true;
};
export const setPhotoAlbumsAction = (payload: IPhotoAlbumResponseModel[]): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.SET_PHOTOALBUMS,
  payload,
});

export const selectPhotoAlbumAction = (payload: IPhotoAlbumResponseModel): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.SELECT_PHOTOALBUM,
  payload,
});
export const clearSelectionAction = (): IPhotoAlbumAction => ({ type: PhotoAlbumActionTypes.CLEAR_SELECTION });
export const selectPhotoAction = (payload: IPhotoResponseModel): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.SELECT_PHOTO,
  payload,
});

export const addPhotoAlbumAction = (payload: IPhotoAlbumResponseModel): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.ADD_PHOTOALBUM,
  payload,
});

export const deletePhotoAlbumAction = (payload: number): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.DELETE_PHOTOALBUM,
  payload,
});

export const addPhotoAction = (payload: IPhotoResponseModel): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.ADD_PHOTO,
  payload,
});

export const deletePhotoAction = (payload: number): IPhotoAlbumAction => ({
  type: PhotoAlbumActionTypes.DELETE_PHOTO,
  payload,
});

export const addPhotoAlbum = (
  projectId: number,
  data: IPhotoAlbumRequestModel,
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const resp = await PhotoAlbumService.add(projectId, data);
    dispatch(addPhotoAlbumAction(resp));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const deletePhotoAlbum = (projectId: number, albumId: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const result = await PhotoAlbumService.deleteAlbum(projectId, albumId);
    if (result) {
      dispatch(deletePhotoAlbumAction(albumId));
    }
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const selectPhotoAlbum = (data: IPhotoAlbumResponseModel): ThunkResult<void> => (dispatch: Dispatch) => {
  try {
    dispatch(selectPhotoAlbumAction(data));
  } catch (e) {
    console.log(e);
  }
};

export const selectPhoto = (data: IPhotoResponseModel): ThunkResult<void> => (dispatch: Dispatch) => {
  try {
    dispatch(selectPhotoAction(data));
  } catch (e) {
    console.log(e);
  }
};
export const clearSelection = (): ThunkResult<void> => (dispatch: Dispatch) => {
  try {
    dispatch(clearSelectionAction());
  } catch (e) {
    console.log(e);
  }
};

export const addPhoto = (projectId: number, albumId: number, data: FormData): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const photo = await PhotoAlbumService.addPhoto(projectId, albumId, data);
    photo.albumId = albumId;
    dispatch(addPhotoAction(photo));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const addPhotos = (
  projectId: number,
  albumId: number,
  arrayOfData: FormData[],
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    await arrayOfData.forEach(async x => {
      try {
        const photo = await PhotoAlbumService.addPhoto(projectId, albumId, x);
        photo.albumId = albumId;
        dispatch(addPhotoAction(photo));
      } catch (e) {
        console.log(e);
      }
    });
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const deletePhoto = (photoId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const result = await PhotoService.deletePhoto(photoId);
    if (result) {
      dispatch(deletePhotoAction(photoId));
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const loadPhotoAlbums = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await PhotoAlbumService.getByProjectId(projectId);
    dispatch(setPhotoAlbumsAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
