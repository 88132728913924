import { Reducer } from 'redux';
import { IFinancialInfoAction, FinancialInfoActionTypes } from '../actions/FinancialInfoAction';
import { IProjectFinancialResponseModel } from '../models/financial';
import { IVersionResponse } from '../models';

export interface IFinancialInfoState {
  financialInfo: IVersionResponse<IProjectFinancialResponseModel>;
}

const initialState: IFinancialInfoState = {
  financialInfo: {
    data: {
      id: 1,
      text: '',
      totalUsed: 0,
      usedMoneyPercent: 0,
      allInvestments: 0,
    },
    id: 0,
    approvalDate: new Date(),
    modifiedDate: new Date(),
    comment: '',
    documents: [],
  },
};

export const FinancialInfoReducer: Reducer<IFinancialInfoState, IFinancialInfoAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FinancialInfoActionTypes.CLEAR__FINANCIAL_INFO:
      return initialState;
    case FinancialInfoActionTypes.SET_FINANCIAL_INFO: {
      const data = payload as IVersionResponse<IProjectFinancialResponseModel>;
      return {
        ...state,
        financialInfo: data,
      };
    }
    default:
      return state;
  }
};
