import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import RealizationService from '../services/RealizationService';
import { IMilestoneResponseModel } from '../models/milestone';
import { IProjectCalendarResponseModel, IProjectStatusResponseModel } from '../models/project';
import { IDocumentResponseModel } from '../models/document';
import { IPhotoResponseModel } from '../models/projectPhoto';
import PhotoService from '../services/PhotoService';

export enum RealizationActionTypes {
  SET_PROJECT_MILESTONES = 'SET_PROJECT_MILESTONES',
  CLEAR_ALL = 'CLEAR_ALL',
  SET_PROJECT_CALENDAR = 'SET_PROJECT_CALENDAR',
  SET_PROJECT_STATUS = 'SET_PROJECT_STATUS',
  SET_MILESTONE_FILTER = 'SET_MILESTONE_FILTER',
  SET_CALENDAR_DATE = 'SET_CALENDAR_DATE',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  SET_EXPIRATION_FILTER = 'SET_EXPIRATION_FILTER',
  SET_PHOTOS = 'SET_PHOTOS',
  UPDATE_PHOTO_URL = 'UPDATE_PHOTO_URL',
}
export interface IRealizationAction extends Action {
  type: RealizationActionTypes;
  payload?:
    | IMilestoneResponseModel[]
    | IProjectCalendarResponseModel
    | IProjectStatusResponseModel
    | Date
    | number
    | boolean
    | IDocumentResponseModel[]
    | IPhotoResponseModel[]
    | IPhotoResponseModel
    | string
    | undefined;
}
export const setExpirationFilterAction = (payload: string): IRealizationAction => ({
  type: RealizationActionTypes.SET_EXPIRATION_FILTER,
  payload,
});
export const setMilestoneFilterAction = (payload: number): IRealizationAction => ({
  type: RealizationActionTypes.SET_MILESTONE_FILTER,
  payload,
});
export const updatePhotoUrlAction = (payload: IPhotoResponseModel): IRealizationAction => ({
  type: RealizationActionTypes.UPDATE_PHOTO_URL,
  payload,
});
export const setPhotosAction = (payload: IPhotoResponseModel[]): IRealizationAction => ({
  type: RealizationActionTypes.SET_PHOTOS,
  payload,
});

export const setDocumentsAction = (payload: IDocumentResponseModel[]): IRealizationAction => ({
  type: RealizationActionTypes.SET_DOCUMENTS,
  payload,
});
export const setCalendarDateAction = (payload: Date): IRealizationAction => ({
  type: RealizationActionTypes.SET_CALENDAR_DATE,
  payload,
});
export const setMilestoneAction = (payload: IMilestoneResponseModel[]): IRealizationAction => ({
  type: RealizationActionTypes.SET_PROJECT_MILESTONES,
  payload,
});
export const setProjectCalendarAction = (payload: IProjectCalendarResponseModel): IRealizationAction => ({
  type: RealizationActionTypes.SET_PROJECT_CALENDAR,
  payload,
});
export const setProjectStatusAction = (payload: IProjectStatusResponseModel): IRealizationAction => ({
  type: RealizationActionTypes.SET_PROJECT_STATUS,
  payload,
});
export const clearAllAction = (): IRealizationAction => ({
  type: RealizationActionTypes.CLEAR_ALL,
});

export const setCalendarDate = (date: Date): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(setCalendarDateAction(date));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const setMilestoneFilter = (filter: number): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(setMilestoneFilterAction(filter));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const setExpirationFilter = (filter: string): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(setExpirationFilterAction(filter));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const clearAll = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(clearAllAction());
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const loadMilestones = (projectId: number, date?: Date): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await RealizationService.getMilestones(projectId, date);
    dispatch(setMilestoneAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadDocuments = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await RealizationService.getDocuments(projectId);
    dispatch(setDocumentsAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadPhotos = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await RealizationService.getPhotos(projectId);
    dispatch(setPhotosAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadPhoto = (photo: IPhotoResponseModel): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await PhotoService.getFileByObjectId(photo.fileObjectId);
    const url = URL.createObjectURL(data);
    const newData = { ...photo, url };
    dispatch(updatePhotoUrlAction(newData));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadStatus = (projectId: number, date?: Date): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await RealizationService.getStatus(projectId, date);
    dispatch(setProjectStatusAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadCalendar = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await RealizationService.getCalendar(projectId);
    dispatch(setProjectCalendarAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
