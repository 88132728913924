import { Reducer } from 'redux';
import { NotificationActionTypes, INotificationAction } from '../actions/NotificationAction';
import { INotificationModel } from '../models/notification';

export interface INotificationState {
  notifications: INotificationModel[];
}

export const initialState: INotificationState = {
  notifications: [],
};

export const NotificationReducer: Reducer<INotificationState, INotificationAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case NotificationActionTypes.CLEAR: {
      return {
        notifications: [],
      };
    }
    case NotificationActionTypes.ADD: {
      return {
        ...state,
        notifications: [...state.notifications, payload as INotificationModel],
      };
    }
    case NotificationActionTypes.REMOVE: {
      const id = payload as string;
      return {
        ...state,
        notifications: state.notifications.filter(x => x.id !== id),
      };
    }
    default:
      return state;
  }
};
