import { ServiceBase, IResponse } from './ServiceBase';
import { IDocumentFileResponseModel } from '../models/document';

export default class DocumentFileService extends ServiceBase {
  protected static BASE_URL = '/Document';

  public static async getFileByObjectId(id: number): Promise<Blob> {
    const res = await this.get<Blob>(`/${id}`, undefined, { responseType: 'blob' });
    return res.data;
  }

  public static async upload(data: FormData): Promise<IDocumentFileResponseModel> {
    const res = await this.post<IResponse<IDocumentFileResponseModel>>(``, data);
    return res.data.data;
  }
}
