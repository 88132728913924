import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import { IAuthorizationState, AuthorizationReducer } from './AuthorizationReducer';
import { INotificationState, NotificationReducer } from './NotificationReducer';
import { IProjectState, ProjectReducer } from './ProjectReducer';
import { IStageState, StageReducer } from './StageReducer';
import { IMilestoneState, MilestoneReducer } from './MilestoneReducer';
import { IRealizationState, RealizationReducer } from './RealizationReducer';
import { IDocumentState, DocumentReducer } from './DocumentReducer';
import { IParticipantState, ParticipantReducer } from './ParticipantReducer';
import { IIndicatorState, IndicatorReducer } from './IndicatorReducer';
import { IPhotoAlbumState, PhotoAlbumReducer } from './PhotoAlbumReducer';
import { IMarketingInfoState, MarketingInfoReducer } from './MarketingInfoReducer';
import { IFinancialInfoState, FinancialInfoReducer } from './FinancialReducer';
import { ICameraState, CameraReducer } from './CameraReducer';
import { ISettingsaState, SettingsReducer } from './SettingsReducer';
import { ITemplateState, TemplateReducer } from './TemplateReducer';
import { ILoadingState, LoadingReducer } from './LoadingReducer';
import { ISalesState, SalesReducer } from './SalesReducer';
import { IUsersState, UserReducer } from './UserReducer';

export interface IRootState {
  router: RouterState;
  authorization: IAuthorizationState;
  notifications: INotificationState;
  projects: IProjectState;
  stages: IStageState;
  milestones: IMilestoneState;
  realization: IRealizationState;
  documents: IDocumentState;
  participants: IParticipantState;
  indicators: IIndicatorState;
  photoAlbums: IPhotoAlbumState;
  financialInfo: IFinancialInfoState;
  marketingInfo: IMarketingInfoState;
  cameras: ICameraState;
  settings: ISettingsaState;
  templates: ITemplateState;
  loadings: ILoadingState;
  sales: ISalesState;
  users: IUsersState;
}

export default function(history: History): Reducer<IRootState> {
  return combineReducers({
    router: connectRouter(history as any),
    authorization: AuthorizationReducer,
    notifications: NotificationReducer,
    projects: ProjectReducer,
    stages: StageReducer,
    milestones: MilestoneReducer,
    realization: RealizationReducer,
    documents: DocumentReducer,
    participants: ParticipantReducer,
    indicators: IndicatorReducer,
    photoAlbums: PhotoAlbumReducer,
    marketingInfo: MarketingInfoReducer,
    financialInfo: FinancialInfoReducer,
    cameras: CameraReducer,
    settings: SettingsReducer,
    templates: TemplateReducer,
    loadings: LoadingReducer,
    sales: SalesReducer,
    users: UserReducer,
  });
}
