import { Action, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { IUserModel } from '../models/account';
import { ThunkResult } from './index';
import AccountService from '../services/AccountService';
import { setAuthorizationToken, removeAuthorizationToken } from '../services/ServiceBase';
import { getRoute, LOGIN } from '../constants/pagesNames';
import { clearNotificationAction } from './NotificationAction';

export enum AuthorizationActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  SET_ACCOUNT = 'SET_ACCOUNT',
  ADD_AUTHORIZATION_ERROR = 'ADD_AUTHORIZATION_ERROR',
  CLEAR_AUTHORIZATION_ERROR = 'CLEAR_AUTHORIZATION_ERROR',
}
export interface IAuthorizationActions extends Action {
  type: AuthorizationActionTypes;
  payload?: IUserModel | string;
}
export const loginSuccessAction = (payload: string): IAuthorizationActions => ({
  type: AuthorizationActionTypes.LOGIN_SUCCESS,
  payload,
});
export const setAccountAction = (payload: IUserModel): IAuthorizationActions => ({
  type: AuthorizationActionTypes.SET_ACCOUNT,
  payload,
});
export const logoutAction = (): IAuthorizationActions => ({ type: AuthorizationActionTypes.LOGOUT });

export const addErrorAction = (error: string): IAuthorizationActions => ({
  type: AuthorizationActionTypes.ADD_AUTHORIZATION_ERROR,
  payload: error,
});

export const clearErrorAction = () => ({ type: AuthorizationActionTypes.CLEAR_AUTHORIZATION_ERROR });
export const clearError = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearErrorAction());
  return true;
};

export const logIn = (email: string, password: string): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  dispatch(clearErrorAction());

  try {
    const token = await AccountService.login(email, password);
    setAuthorizationToken(token);
    dispatch(clearNotificationAction());
    dispatch(loginSuccessAction(token));
    const user = await AccountService.getCurrentUser();
    dispatch(setAccountAction(user));
    dispatch(push('/projects'));
    return true;
  } catch (e) {
    dispatch(addErrorAction(e.message));
  }
  return false;
};

export const logOut = (): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  dispatch(clearErrorAction());
  try {
    dispatch(logoutAction());
    removeAuthorizationToken();
    dispatch(push(getRoute(LOGIN)));
  } catch (e) {
    dispatch(addErrorAction(e.message));
    return false;
  }
  return true;
};
