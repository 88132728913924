import { ServiceBase, IResponse } from './ServiceBase';
import { IParticipantModel, IParticipantRequestModel } from '../models/participant';

export default class ParticipantService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number): Promise<IParticipantModel[]> {
    const res = await this.get<IResponse<IParticipantModel[]>>(`/${id}/Team`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getAll(): Promise<IParticipantModel[]> {
    const res = await this.get<IResponse<IParticipantModel[]>>(`/Team`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async add(id: number, data: IParticipantRequestModel): Promise<IParticipantModel> {
    const res = await this.post<IResponse<IParticipantModel>>(`/${id}/Team`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async update(
    projectId: number,
    id: number,
    data: IParticipantRequestModel,
  ): Promise<IParticipantModel> {
    const res = await this.put<IResponse<IParticipantModel>>(`/${projectId}/Team/${id}`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async deleteById(projectId: number, id: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/${projectId}/Team/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
