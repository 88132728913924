import { ServiceBase, IResponse } from './ServiceBase';
import { ISettingsResponseModel, ISettingsRequestModel } from '../models/settings';

export default class SettingsService extends ServiceBase {
  protected static BASE_URL = '/Admin';

  public static async getSettings(): Promise<ISettingsResponseModel> {
    const res = await this.get<IResponse<ISettingsResponseModel>>(`/Settings`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async save(data: ISettingsRequestModel): Promise<ISettingsResponseModel> {
    const res = await this.put<IResponse<ISettingsResponseModel>>(`/Settings`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
