import { Reducer } from 'redux';
import { IIndicatorAction, IndicatorActionTypes } from '../actions/IndicatorAction';
import { IProjectIndicatorResponseModel } from '../models/projectIndicator';
import { IVersionResponse } from '../models';

export interface IIndicatorState {
  indicatorsData: IVersionResponse<IProjectIndicatorResponseModel[]>;
}

const initialState: IIndicatorState = {
  indicatorsData: {
    comment: '',
    data: [],
    documents: [],
    id: 0,
    approvalDate: new Date(),
    modifiedDate: new Date(),
  },
};
export const IndicatorReducer: Reducer<IIndicatorState, IIndicatorAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case IndicatorActionTypes.CLEAR_INDICATORS:
      return initialState;
    case IndicatorActionTypes.SET_INDICATORS:
      return {
        ...state,
        indicatorsData: payload as IVersionResponse<IProjectIndicatorResponseModel[]>,
      };
    default:
      return state;
  }
};
