export enum NotificationTypes {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}
export interface INotificationModel {
  code: number;
  message: string;
  type: string;
  id: string;
}
