import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { push } from 'connected-react-router';
import { NotificationActionTypes } from '../actions/NotificationAction';
import { getRoute, LOGIN } from '../constants/pagesNames';
import { VersionAPI } from '../constants/versionAPI';
import { store } from '../index';
import Guid from '../components/Helpers/Guid';
import { NotificationTypes } from '../models/notification';
import { LoadingActionTypes } from '../actions/LoadingAction';

export interface IResponse<T> {
  data: T;
  meta?: IMeta;
  success: boolean;
  error?: string;
}
export interface IMeta {
  totalCount: number;
  limit: number;
  offset: number;
}

enum Urls {
  prod = 'https://dpback.mgcpn.ru',
  dev = 'http://5.189.231.34:9600',
  local = 'http://localhost:3000',
}

const setServerUrl = (): Urls => {
  switch (process.env.REACT_APP_BUILD) {
    case 'prod':
      return Urls.prod;
    case 'dev':
      return Urls.dev;
    default:
      return Urls.dev;
  }
};

export const SERVER_URL = `${setServerUrl()}/api/${VersionAPI.VERSION10}`;
export const PHOTO_URL = `${setServerUrl()}/api/${VersionAPI.VERSION10}/Photo`;

export const api: AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
  },
});

api.interceptors.response.use(
  (response): AxiosPromise<IResponse<any>> => {
    store.dispatch({
      type: LoadingActionTypes.REMOVE,
    });
    if (response.status === 204) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  },
  error => {
    store.dispatch({
      type: LoadingActionTypes.REMOVE,
    });
    const message = error.response.data && error.response.data.error ? error.response.data.error : undefined;
    // console.log('error: ', JSON.parse(JSON.stringify(error)));
    // console.log('error: ', error);
    if (error.response === undefined) {
      store.dispatch({
        type: NotificationActionTypes.ADD,
        payload: {
          code: 503,
          id: Guid.newGuid(),
          message: message || 'Ресурс временно не доступен',
          type: NotificationTypes.error,
        },
      });
    } else {
      if (error.response.status === 400) {
        store.dispatch({
          type: NotificationActionTypes.ADD,
          payload: {
            code: 400,
            id: Guid.newGuid(),
            message: message || 'не верный запрос',
            type: NotificationTypes.error,
          },
        });
      } else if (error.response.status === 404) {
        store.dispatch({
          type: NotificationActionTypes.ADD,
          payload: {
            code: 404,
            id: Guid.newGuid(),
            message: message || 'ресурс не найден',
            type: NotificationTypes.error,
          },
        });
      } else if (error.response.status === 401) {
        store.dispatch(push(getRoute(LOGIN)));
      } else if (error.response.status === 409) {
        store.dispatch({
          type: NotificationActionTypes.ADD,
          payload: {
            code: 409,
            id: Guid.newGuid(),
            message: message || 'конфликт запроса с текущим состоянием сервера',
            type: NotificationTypes.error,
          },
        });
      } else {
        store.dispatch({
          type: NotificationActionTypes.ADD,
          payload: {
            code: 500,
            id: Guid.newGuid(),
            message: error.response.data && error.response.data.error ? error.response.data.error : undefined,
            type: NotificationTypes.error,
          },
        });
      }
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

export const setAuthorizationToken = (token: string) => {
  api.defaults.headers.Authorization = `bearer ${token}`;
};

export const removeAuthorizationToken = () => {
  api.defaults.headers.Authorization = '';
};

export abstract class ServiceBase {
  protected static BASE_URL: string = '';

  protected static getUrl(url: string): string {
    return `${this.BASE_URL}${url}`;
  }

  protected static async http(method: string, url: string, data?: any, options: AxiosRequestConfig = {}): Promise<any> {
    store.dispatch({
      type: LoadingActionTypes.ADD,
    });
    const response = await api.request({
      method: method.toUpperCase(),
      url: this.getUrl(url),
      data,
      ...options,
    });
    return response;
  }

  protected static get<T>(url: string, data?: any, options?: AxiosRequestConfig): AxiosPromise<T> {
    return this.http('GET', url, data, options);
  }

  protected static post<T>(url: string, data?: any, options?: AxiosRequestConfig): AxiosPromise<T> {
    return this.http('POST', url, data, options);
  }

  protected static put<T>(url: string, data?: any, options?: AxiosRequestConfig): AxiosPromise<T> {
    return this.http('PUT', url, data, options);
  }

  protected static delete<T>(url: string, data?: any, options?: AxiosRequestConfig): AxiosPromise<T> {
    return this.http('DELETE', url, data, options);
  }
}
