import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import { StageService } from '../services/StageService';
import { IStageResponseModel } from '../models/stage';

export enum StageActionTypes {
  SET_STAGES = 'SET_STAGES',
  SET_STAGE = 'SET_STAGE',
  SET_TAKING_STAGE_TO_WORK = 'SET_TAKING_STAGE_TO_WORK',
  CLEAR_STAGES = 'CLEAR_STAGES',
}

export interface IStageAction extends Action {
  type: StageActionTypes;
  payload?: boolean | IStageResponseModel | IStageResponseModel[];
}

export const clearStagesAction = (): IStageAction => ({
  type: StageActionTypes.CLEAR_STAGES,
});
export const clearStages = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearStagesAction());
  return true;
};
export const setStagesAction = (payload: IStageResponseModel[]): IStageAction => ({
  type: StageActionTypes.SET_STAGES,
  payload,
});

export const setStageAction = (payload: IStageResponseModel): IStageAction => ({
  type: StageActionTypes.SET_STAGE,
  payload,
});

export const setTakingStageToWorkAction = (payload: boolean): IStageAction => ({
  type: StageActionTypes.SET_TAKING_STAGE_TO_WORK,
  payload,
});

export const loadStages = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await StageService.getByProjectId(projectId);

    dispatch(setStagesAction(data));

    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};

export const takeStageToWork = (projectId: number, stageId: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  dispatch(setTakingStageToWorkAction(true));

  try {
    const data = await StageService.takeStageToWork(projectId, stageId);

    dispatch(setStageAction(data));
    dispatch(setTakingStageToWorkAction(false));

    return true;
  } catch (e) {
    dispatch(setTakingStageToWorkAction(false));

    console.log(e);
  }

  return false;
};
