import { Reducer } from 'redux';
import { ITemplateAction, TemplateActionTypes } from '../actions/TemplateAction';
import { ITemplateResponse } from '../models/template';

export interface ITemplateState {
  templates: ITemplateResponse[];
  selectedTemplate: ITemplateResponse;
}

const initialState: ITemplateState = {
  templates: [],
  selectedTemplate: {
    description: '',
    id: 0,
    milestoneCount: 0,
    milestones: [],
    name: '',
    stageCount: 0,
  },
};

export const TemplateReducer: Reducer<ITemplateState, ITemplateAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case TemplateActionTypes.SET_TEMPLATES:
      return {
        ...state,
        templates: payload as ITemplateResponse[],
      };
    case TemplateActionTypes.CLEAR_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: initialState.selectedTemplate,
      };
    case TemplateActionTypes.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: payload as ITemplateResponse,
      };
    case TemplateActionTypes.DELETE_TEMPLATE: {
      const tmplId = payload as number;
      return {
        ...state,
        templates: state.templates.filter(x => x.id !== tmplId),
      };
    }
    case TemplateActionTypes.UPDATE_TEMPLATE: {
      const tmpl = payload as ITemplateResponse;
      return {
        ...state,
        templates: state.templates.map(x => (x.id === tmpl.id ? tmpl : x)),
      };
    }
    case TemplateActionTypes.ADD_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, payload as ITemplateResponse],
      };
    default:
      return state;
  }
};
