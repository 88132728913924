import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import { ICameraResponseModel, ICameraRequestModel } from '../models/camera';
import CameraService from '../services/CameraService';

export enum CameraActionTypes {
  SET_CAMERAS = 'SET_CAMERAS',
  ADD_CAMERA = 'ADD_CAMERA',
  DELETE_CAMERA = 'DELETE_Camera',
  EDIT_CAMERA = 'EDIT_CAMERA',
  CHANGE_STATE = 'CHANGE_STATE',
  CLEAR_CAMERAS = 'CLEAR_CAMERAS',
}
export interface ICameraAction extends Action {
  type: CameraActionTypes;
  payload?: ICameraResponseModel[] | ICameraResponseModel | number;
}
export const setCamerasAction = (payload: ICameraResponseModel[]): ICameraAction => ({
  type: CameraActionTypes.SET_CAMERAS,
  payload,
});
export const changeStateAction = (payload: ICameraResponseModel): ICameraAction => ({
  type: CameraActionTypes.CHANGE_STATE,
  payload,
});
export const addCameraAction = (payload: ICameraResponseModel): ICameraAction => ({
  type: CameraActionTypes.ADD_CAMERA,
  payload,
});
export const deleteCameraAction = (payload: number): ICameraAction => ({
  type: CameraActionTypes.DELETE_CAMERA,
  payload,
});
export const editCameraAction = (payload: ICameraResponseModel): ICameraAction => ({
  type: CameraActionTypes.EDIT_CAMERA,
  payload,
});

export const cleatAction = (): ICameraAction => ({
  type: CameraActionTypes.CLEAR_CAMERAS,
});
export const clearCameras = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const addCamera = (projectId: number, data: ICameraRequestModel): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const result = await CameraService.add(projectId, data);
    dispatch(addCameraAction(result));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const editCamera = (projectId: number, data: ICameraRequestModel): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const value = data as ICameraResponseModel;
    dispatch(editCameraAction(value));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const changeState = (data: ICameraResponseModel): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(changeStateAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const deleteCamera = (projectId: number, id: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const result = await CameraService.deleteById(projectId, id);
    if (result) {
      dispatch(deleteCameraAction(id));
    }
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadCameras = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const result = await CameraService.getByProjectId(projectId);
    dispatch(setCamerasAction(result));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
