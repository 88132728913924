import { IResponse, ServiceBase } from './ServiceBase';
import { IRoleResponseModel, IUserRoleResponseModel, IUserRoleRequestModel } from '../models/account';

export default class UserService extends ServiceBase {
  protected static BASE_URL = '/Admin';

  public static async getRoleList(): Promise<IRoleResponseModel[]> {
    const res = await this.get<IResponse<IRoleResponseModel[]>>('/Roles');
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async deleteUser(userId: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/UserRoles?userId=${userId}`);
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async updateUserRole(userId: number, roleId: number): Promise<IUserRoleResponseModel> {
    const res = await this.put<IResponse<IUserRoleResponseModel>>(`/UserRoles?userId=${userId}&roleId=${roleId}`);
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async addUserRole(data: IUserRoleRequestModel): Promise<IUserRoleResponseModel> {
    const res = await this.post<IResponse<IUserRoleResponseModel>>('/UserRoles', data);
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async getUserRoleList(): Promise<IUserRoleResponseModel[]> {
    const res = await this.get<IResponse<IUserRoleResponseModel[]>>('/UserRoles');
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }
}
