import { Action, Dispatch } from 'redux';
import { IProjectModel } from '../models/project';
import ProjectService from '../services/ProjectService';
import { ThunkResult } from './index';
import RoleNames from '../constants/roleNames';
import { IUserModel } from '../models/account';

export enum ProjectActionTypes {
  SET_PROJECTS = 'SET_PROJECTS',
  SET_SELECTED_PROJECT_ID = 'SET_SELECTED_PROJECT_ID',
  SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT',
  SET_FILTER = 'SET_FILTER',
  CLEAR_PROJECTS = 'CLEAR_PROJECTS',
  SET_FINANCIAL_VERSIONID = 'SET_FINANCIAL_VERSIONID',
  SET_INDICATOR_VERSIONID = 'SET_INDICATOR_VERSIONID',
  SET_STATUS_VERSIONID = 'SET_STATUS_VERSIONID',
  SET_SALES_VERSIONID = 'SET_SALES_VERSIONID',
  ADD_STAGE_FILTER = 'ADD_STAGE_FILTER',
  REMOVE_STAGE_FILTER = 'REMOVE_STAGE_FILTER',
  CLEAR_STAGE_FILTER = 'CLEAR_STAGE_FILTER',
  CHANGE_PROJECT_NAME = 'CHANGE_PROJECT_NAME',
  CHANGE_PROJECT_MANAGER = 'CHANGE_PROJECT_MANAGER',
  CLEAR_ALL_PROJECTS_DATA = 'CLEAR_ALL_PROJECTS_DATA',
}
export interface IProjectAction extends Action {
  type: ProjectActionTypes;
  payload?: IProjectModel | IProjectModel[] | string | number | IUserModel;
}
export const setSelectedProjectAction = (payload: IProjectModel): IProjectAction => ({
  type: ProjectActionTypes.SET_SELECTED_PROJECT,
  payload,
});
export const clearAllAction = () => ({
  type: ProjectActionTypes.CLEAR_ALL_PROJECTS_DATA,
});
export const clearAll = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearAllAction());
  return true;
};
export const setSelectedProjectIdAction = (payload: number): IProjectAction => ({
  type: ProjectActionTypes.SET_SELECTED_PROJECT_ID,
  payload,
});
export const setSalesVersionIdAction = (payload: number): IProjectAction => ({
  type: ProjectActionTypes.SET_SALES_VERSIONID,
  payload,
});
export const setStatusVersionIdAction = (payload: number): IProjectAction => ({
  type: ProjectActionTypes.SET_STATUS_VERSIONID,
  payload,
});
export const setFinancialVersionIdAction = (payload: number): IProjectAction => ({
  type: ProjectActionTypes.SET_FINANCIAL_VERSIONID,
  payload,
});
export const setIndicatorlVersionIdAction = (payload: number): IProjectAction => ({
  type: ProjectActionTypes.SET_INDICATOR_VERSIONID,
  payload,
});
export const setProjectFilterAction = (payload: string): IProjectAction => ({
  type: ProjectActionTypes.SET_FILTER,
  payload,
});
export const changeProjectNameAction = (payload: string): IProjectAction => ({
  type: ProjectActionTypes.CHANGE_PROJECT_NAME,
  payload,
});
export const changeProjectManagerAction = (payload: IUserModel): IProjectAction => ({
  type: ProjectActionTypes.CHANGE_PROJECT_MANAGER,
  payload,
});
export const addStageFilterAction = (payload: string): IProjectAction => ({
  type: ProjectActionTypes.ADD_STAGE_FILTER,
  payload,
});
export const removeStageFilterAction = (payload: string): IProjectAction => ({
  type: ProjectActionTypes.REMOVE_STAGE_FILTER,
  payload,
});
export const clearStageFilterAction = (): IProjectAction => ({
  type: ProjectActionTypes.CLEAR_STAGE_FILTER,
});
export const setProjectsAction = (payload: IProjectModel[]): IProjectAction => ({
  type: ProjectActionTypes.SET_PROJECTS,
  payload,
});
export const setSalesVersionId = (id: number): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(setSalesVersionIdAction(id));
  return true;
};
export const setStatusVersionId = (id: number): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(setStatusVersionIdAction(id));
  return true;
};
export const setFinancialVersiontId = (data: number): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(setFinancialVersionIdAction(data));
  return true;
};
export const setIndicatorVersionId = (data: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  dispatch(setIndicatorlVersionIdAction(data));
  return true;
};
export const setProjectId = (data: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  dispatch(setSelectedProjectIdAction(data));
  return true;
};
export const setProject = (data: IProjectModel): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(setSelectedProjectAction(data));
  return true;
};

export const loadProjectById = (id: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await ProjectService.getById(id);
    dispatch(setSelectedProjectAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const setProjectFilter = (filter: string): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(setProjectFilterAction(filter));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const loadProjects = (roleName: string): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    let data = null;
    switch (roleName) {
      case RoleNames.Manager:
        data = await ProjectService.getForManager();
        break;
      default:
        data = await ProjectService.getList();
        break;
    }
    dispatch(setProjectsAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const addStageFilter = (filter: string): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(addStageFilterAction(filter));
  return true;
};

export const changeProjectName = (name: string): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(changeProjectNameAction(name));
  return true;
};
export const changeProjectManager = (data: IUserModel): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(changeProjectManagerAction(data));
  return true;
};
export const removeStageFilter = (filter: string): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(removeStageFilterAction(filter));
  return true;
};
export const clearStageFilter = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearStageFilterAction());
  return true;
};
