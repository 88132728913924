import { ServiceBase, IResponse } from './ServiceBase';
import { IProjectFinancialResponseModel, IProjectFinancialRequestModel } from '../models/financial';
import { IVersionRequestModel, IVersionResponse } from '../models';

export default class FinancialService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(
    id: number,
    versionId?: number,
  ): Promise<IVersionResponse<IProjectFinancialResponseModel>> {
    let url = `/${id}/FinancialInformation`;
    if (versionId && versionId > 0) {
      url += `/?versionId=${versionId}`;
    }
    const res = await this.get<IResponse<IVersionResponse<IProjectFinancialResponseModel>>>(url);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async save(
    projectId: number,
    data: IVersionRequestModel<IProjectFinancialRequestModel>,
  ): Promise<IVersionResponse<IProjectFinancialResponseModel>> {
    const res = await this.put<IResponse<IVersionResponse<IProjectFinancialResponseModel>>>(
      `/${projectId}/FinancialInformation`,
      data,
    );
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
