import findIndex from 'lodash/findIndex';
import moment from 'moment';
import DocumentFileService from '../services/DocumentFileService';
import IndicatorTypes from './indicatorTypes';
import { IIndicatorRquestModel, IProjectIndicatorResponseModel } from '../models/projectIndicator';
import { IAdminMilestoneResponse } from '../models/milestone';
import MilestoneStateTypes from './MilestoneStateTypes';
import { NotificationTypes } from '../models/notification';
import { SERVER_URL } from '../services/ServiceBase';

export default (email: string) => {
  // tslint:disable-next-line:max-line-length
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const downloadBlob = (data: Blob, name: string) => {
  const a = document.createElement('a');
  const url = URL.createObjectURL(data);
  a.href = url;
  a.target = 'blank';
  a.download = name;
  a.click();
  a.remove();
  return true;
};

export const getNotificationTypeName = (type: string): string => {
  switch (type) {
    case NotificationTypes.error:
      return 'Ошибка';
    case NotificationTypes.warning:
      return 'Предупреждение';
    case NotificationTypes.info:
      return 'Сообщение';
    default:
      return 'Уведомление';
  }
};
export const parseUrl = (data:string):string=>{
  if(data){
  return data.replace('%SERVER_URL%', SERVER_URL)
  .replace('http://10.0.206.42:1000/api/v1.0',SERVER_URL);
  }else{
    return data;
  }
}

export const validateMilestones = (milestones: IAdminMilestoneResponse[]): string[] => {
  let dublicateName = false;
  milestones.reduce((ar: string[], m) => {
    if (ar.indexOf(m.name) >= 0) {
      dublicateName = true;
    } else {
      ar.push(m.name);
    }
    return ar;
  }, []);
  const emptyName = milestones.find(x => !x.name);
  const emptyIndicator = milestones.find(x => Array.isArray(x.indicatorIds) && x.indicatorIds.indexOf(0) >= 0);
  const emptyDocument = milestones.find(x => Array.isArray(x.documentNames) && x.documentNames.indexOf('') >= 0);
  const emptyDirectory = milestones.find(x => !x.directoryName || x.directoryName === null);
  const result: string[] = [];
  if (dublicateName) result.push('Название новой КТ совпадает с существующей');
  if (emptyName) result.push('Введите название КТ');
  if (emptyIndicator) result.push('Выберите ТЭП из списка');
  if (emptyDocument) result.push('Введите имя документа');
  if (emptyDirectory) result.push('Введите название проектной папки');
  return result;
};
export const getProjectStatusClass = (status: string): string => {
  switch (status) {
    case MilestoneStateTypes.AllowExpiration:
      return 'middl';
    case MilestoneStateTypes.NotAllowExpiration:
      return 'bad';
    default:
      return 'good';
  }
};
export const downloadDocument = (id: number, name: string) => {
  DocumentFileService.getFileByObjectId(id)
    .then(data => {
      const a = document.createElement('a');
      const url = URL.createObjectURL(data);
      a.href = url;
      a.target = 'blank';
      a.download = name;
      a.click();
      a.remove();
      return true;
    })
    .catch(e => console.log(e));
};
export const getIndicatorValue = (data: IIndicatorRquestModel, view?: boolean): any => {
  switch (data.type) {
    case IndicatorTypes.integer:
      return data.integer == null ? 0 : data.integer;
    case IndicatorTypes.double: {
      if (!view) {
        return data.double == null ? 0 : data.double;
      }
      return data.double == null ? 0 : Number.parseFloat(data.double.toString());
    }
    default:
      return data.text === null ? '' : data.text;
  }
};
export const isDateEqual = (date1: string, date2: string): boolean => {
  const m1 = moment(date1);
  const m2 = moment(date2);
  return m1.dayOfYear() === m2.dayOfYear() && m1.year() === m2.year();
};

export const isDateMoreThen = (date1: string, date2: string): boolean => {
  const m1 = moment(date1);
  const m2 = moment(date2);
  return m1.year() > m2.year() || (m1.dayOfYear() > m2.dayOfYear() && m1.year() === m2.year());
};
export const isDateMoreThenFor = (date1: string, date2: string, daysCount: number): boolean => {
  const m1 = moment(date1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const m2 = moment(date2).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return m1.diff(m2, 'days') > daysCount;
};
export const isDateLessThenFor = (date1: string, date2: string, daysCount: number): boolean => {
  const m1 = moment(date1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const m2 = moment(date2).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return m1.diff(m2, 'days') < daysCount;
};
export const isDateLessThen = (date1: string, date2: string): boolean => {
  const m1 = moment(date1);
  const m2 = moment(date2);
  return m1.year() < m2.year() || (m1.dayOfYear() < m2.dayOfYear() && m1.year() === m2.year());
};
export const getChangedArrayElements = <T>(newArray: Array<T>, oldArray: Array<T>): Array<T> => {
  if (JSON.stringify(newArray) === JSON.stringify(oldArray)) return [];

  const changes: Array<T> = [];

  newArray.forEach((item, index) => {
    if (JSON.stringify(item) !== JSON.stringify(oldArray[index])) {
      changes.push(item as T);
    }
  });
  return changes;
};

export const setIndicatorValue = (data: IProjectIndicatorResponseModel, value: any): IProjectIndicatorResponseModel => {
  const integer = parseInt(value, 10);
  switch (data.type) {
    case IndicatorTypes.integer:
      return { ...data, integer: Number.isNaN(integer) ? data.integer : integer };
    case IndicatorTypes.double: {
      return {
        ...data,
        double: Number.isNaN(value) ? data.double : ((value as string).replace(',', '.') as any),
      };
    }
    default:
      return { ...data, text: value };
  }
};

export const replaceInArray = (array: any[], item: any, key: string): any[] => {
  const itemIndex = findIndex(array, i => i[key] === item[key]);
  const newArray = [...array];

  newArray[itemIndex] = item;

  return newArray;
};
