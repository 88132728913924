import { Reducer } from 'redux';
import { IDocumentAction, DocumentActionTypes } from '../actions/DocumentAction';
import { IDocumentResponseModel } from '../models/document';

export interface IDocumentState {
  documents: IDocumentResponseModel[];
}

const initialState: IDocumentState = {
  documents: [],
};
export const DocumentReducer: Reducer<IDocumentState, IDocumentAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case DocumentActionTypes.CLEAR_DOCUMENTS:
      return initialState;
    case DocumentActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: payload as IDocumentResponseModel[],
      };
    default:
      return state;
  }
};
