import { Reducer } from 'redux';
import { IStageAction, StageActionTypes } from '../actions/StageAction';
import { IStageResponseModel } from '../models/stage';
import { replaceInArray } from '../constants/functions';

export interface IStageState {
  stages: IStageResponseModel[];
  isTakingToWork: boolean;
}

const initialState: IStageState = {
  stages: [],
  isTakingToWork: false,
};

export const StageReducer: Reducer<IStageState, IStageAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case StageActionTypes.CLEAR_STAGES:
      return initialState;
    case StageActionTypes.SET_STAGES:
      return {
        ...state,
        stages: payload as IStageResponseModel[],
      };

    case StageActionTypes.SET_STAGE:
      return {
        ...state,
        stages: replaceInArray(state.stages, payload, 'id'),
      };

    case StageActionTypes.SET_TAKING_STAGE_TO_WORK:
      return {
        ...state,
        isTakingToWork: payload as boolean,
      };

    default:
      return state;
  }
};
