import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import { ISettingsResponseModel } from '../models/settings';
import SettingsService from '../services/SettingsService';

export enum SettingsActionTypes {
  SET_SETTINGS = 'SET_SETTINGS',
  CLEAR_SETTINGS = 'CLEAR_SETTINGS',
}
export interface ISettingsAction extends Action {
  type: SettingsActionTypes;
  payload?: ISettingsResponseModel;
}
export const setSettingsAction = (payload: ISettingsResponseModel): ISettingsAction => ({
  type: SettingsActionTypes.SET_SETTINGS,
  payload,
});
export const clearSettingsAction = (): ISettingsAction => ({
  type: SettingsActionTypes.CLEAR_SETTINGS,
});
export const clearSettings = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearSettingsAction());
  return true;
};

export const loadSettings = (): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const result = await SettingsService.getSettings();
    dispatch(setSettingsAction(result));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
