import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import { TemplateService } from '../services/TemplateService';
import { ITemplateRequest, ITemplateResponse } from '../models/template';

export enum TemplateActionTypes {
  SET_TEMPLATES = 'SET_TEMPLATES',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE',
  CLEAR_SELECTED_TEMPLATE = 'CLEAR_SELECTED_TEMPLATE',
}

export interface ITemplateAction extends Action {
  type: TemplateActionTypes;
  payload?: number | ITemplateResponse | ITemplateResponse[];
}

export const setTemplatesAction = (payload: ITemplateResponse[]): ITemplateAction => ({
  type: TemplateActionTypes.SET_TEMPLATES,
  payload,
});

export const addTemplateAction = (payload: ITemplateResponse): ITemplateAction => ({
  type: TemplateActionTypes.ADD_TEMPLATE,
  payload,
});
export const setSelectedTemplateAction = (payload: ITemplateResponse): ITemplateAction => ({
  type: TemplateActionTypes.SET_SELECTED_TEMPLATE,
  payload,
});
export const clearSelectedTemplateAction = (): ITemplateAction => ({
  type: TemplateActionTypes.CLEAR_SELECTED_TEMPLATE,
});

export const updateTemplateAction = (payload: ITemplateResponse): ITemplateAction => ({
  type: TemplateActionTypes.UPDATE_TEMPLATE,
  payload,
});

export const deleteTemplateAction = (payload: number): ITemplateAction => ({
  type: TemplateActionTypes.DELETE_TEMPLATE,
  payload,
});

export const updateTemplate = (id: number, data: ITemplateRequest): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const res = await TemplateService.update(data, id);
    dispatch(updateTemplateAction(res));
    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};

export const addTemplate = (data: ITemplateRequest): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const res = await TemplateService.add(data);
    dispatch(addTemplateAction(res));
    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};
export const clearSelection = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  try {
    dispatch(clearSelectedTemplateAction());
    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};
export const loadTemplate = (id: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await TemplateService.getById(id);
    dispatch(setSelectedTemplateAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};

export const deleteTemplate = (id: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await TemplateService.deleteTemplate(id);
    if (data) {
      dispatch(deleteTemplateAction(id));
      return true;
    }
  } catch (e) {
    console.log(e);
  }

  return false;
};

export const loadTemplates = (): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await TemplateService.getList();

    dispatch(setTemplatesAction(data));

    return true;
  } catch (e) {
    console.log(e);
  }

  return false;
};
