import { ServiceBase, IResponse } from './ServiceBase';
import { ICameraResponseModel, ICameraRequestModel } from '../models/camera';

export default class CameraService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number): Promise<ICameraResponseModel[]> {
    const res = await this.get<IResponse<ICameraResponseModel[]>>(`/${id}/Camera`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async add(id: number, data: ICameraRequestModel): Promise<ICameraResponseModel> {
    const res = await this.post<IResponse<ICameraResponseModel>>(`/${id}/Camera`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async deleteById(projectId: number, id: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/${projectId}/Camera/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
