import { IProjectIndicatorResponseModel, IIndicatorRquestModel } from '../models/projectIndicator';
import { IVersionRequestModel, IVersionResponse } from '../models';
import { IResponse, ServiceBase } from './ServiceBase';

export default class IndicatorService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(
    id: number,
    versionId?: number,
  ): Promise<IVersionResponse<IProjectIndicatorResponseModel[]>> {
    let url = `/${id}/Indicator`;
    if (versionId && versionId > 0) {
      url += `/?versionId=${versionId}`;
    }
    const res = await this.get<IResponse<IVersionResponse<IProjectIndicatorResponseModel[]>>>(url);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async save(
    projectId: number,
    data: IVersionRequestModel<IIndicatorRquestModel[]>,
  ): Promise<IProjectIndicatorResponseModel[]> {
    const res = await this.put<IResponse<IProjectIndicatorResponseModel[]>>(`/${projectId}/Indicator`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
