import { Reducer } from 'redux';
import { ISalesAction, SalesActionTypes } from '../actions/SalesAction';
import { IProjectSalesResponse } from '../models/sales';
import { IVersionResponse } from '../models';

export interface ISalesState {
  sales: IVersionResponse<IProjectSalesResponse>;
  isUpdating: boolean;
}

const initialState: ISalesState = {
  sales: {
    data: {
      text: '',
    },
    id: 0,
    approvalDate: new Date(),
    modifiedDate: new Date(),
    comment: '',
    documents: [],
  },
  isUpdating: false,
};

export const SalesReducer: Reducer<ISalesState, ISalesAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SalesActionTypes.SET_UPDATING:
      return {
        ...state,
        isUpdating: payload as boolean,
      };
    case SalesActionTypes.CLEAR__SALES:
      return initialState;
    case SalesActionTypes.SET_SALES: {
      const data = payload as IVersionResponse<IProjectSalesResponse>;
      return {
        ...state,
        sales: data,
      };
    }
    default:
      return state;
  }
};
