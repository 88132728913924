import { ServiceBase, IResponse } from './ServiceBase';
import { IStageResponseModel } from '../models/stage';

export class StageService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number): Promise<IStageResponseModel[]> {
    const res = await this.get<IResponse<IStageResponseModel[]>>(`/${id}/Stage`);

    return res.data.data;
  }

  public static async takeStageToWork(projectId: number, stageId: number): Promise<IStageResponseModel> {
    const response = await this.put<IResponse<IStageResponseModel>>(`/${projectId}/Stage/${stageId}/TakeToWork`);

    // @ts-ignore
    const { data } = 'response' in response ? response.response : response;

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }
}

export default StageService;
