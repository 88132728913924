import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import IndicatorService from '../services/IndicatorService';
import { IProjectIndicatorResponseModel } from '../models/projectIndicator';
import { IVersionResponse } from '../models';

export enum IndicatorActionTypes {
  SET_INDICATORS = 'SET_INDICATORS',
  CLEAR_INDICATORS = 'CLEAR_INDICATORS',
}
export interface IIndicatorAction extends Action {
  type: IndicatorActionTypes;
  payload?: IVersionResponse<IProjectIndicatorResponseModel[]>;
}
export const setIndicatorAction = (payload: IVersionResponse<IProjectIndicatorResponseModel[]>): IIndicatorAction => ({
  type: IndicatorActionTypes.SET_INDICATORS,
  payload,
});

export const cleatAction = (): IIndicatorAction => ({
  type: IndicatorActionTypes.CLEAR_INDICATORS,
});
export const clearIndicators = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const loadIndicators = (projectId: number, versionId?: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await IndicatorService.getByProjectId(projectId, versionId);
    dispatch(setIndicatorAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
