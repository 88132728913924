import { IDocumentResponseModel } from '../models/document';
import { IResponse, ServiceBase } from './ServiceBase';

export default class DocumentService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number): Promise<IDocumentResponseModel[]> {
    const res = await this.get<IResponse<IDocumentResponseModel[]>>(`/${id}/Document`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
