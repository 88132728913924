import { IResponse, ServiceBase } from './ServiceBase';
import { IUserModel } from '../models/account';

export default class AccountService extends ServiceBase {
  protected static BASE_URL = '/User';

  public static loginError = 'Логин или пароль указаны неверно';

  public static async login(login: string, password: string): Promise<string> {
    const data = {
      login,
      password,
    };

    try {
      const res = await this.post<IResponse<string>>('/Authenticate', data);
      if (res.data.success) return res.data.data;
      throw new Error(this.loginError);
    } catch (e) {
      const errorText = e.response && e.response.status === 401 ? this.loginError : e.message;
      throw new Error(errorText);
    }
  }

  public static async getList(): Promise<IUserModel[]> {
    const res = await this.get<IResponse<IUserModel[]>>('/');
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async getCurrentUser(): Promise<IUserModel> {
    const res = await this.get<IResponse<IUserModel>>('/Current');
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error('Что-то пошло не так. Повтороите попытку ещё раз');
  }
}
