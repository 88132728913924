import { Reducer } from 'redux';
import { ISettingsAction, SettingsActionTypes } from '../actions/SettingsAction';
import { ISettingsResponseModel } from '../models/settings';

export interface ISettingsaState {
  settings: ISettingsResponseModel;
}

const initialState: ISettingsaState = {
  settings: {
    optionalIndicator: '',
    companyDescription:
      'Инвестирование в строительство жилых и нежилых объектов, осуществление функций заказчика и оказание услуг по реализации недвижимости.',
    contactInformation: 'info@mgcpn.ru Москва, ул. Гиляровского 4, к. 1 +7 (495) 487-22-22',
    criticalDeviation: 15,
    uncriticalDeviation: 7,
    updatePeriod: 7,
  },
};

export const SettingsReducer: Reducer<ISettingsaState, ISettingsAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SettingsActionTypes.CLEAR_SETTINGS:
      return initialState;
    case SettingsActionTypes.SET_SETTINGS: {
      return {
        ...state,
        settings: payload as ISettingsResponseModel,
      };
    }
    default:
      return state;
  }
};
