import { Action, Dispatch } from 'redux';
import { IMarketingInfoRequestModel, IMarketingInfoResponseModel } from '../models/marketingInfo';
import MarketingInfoService from '../services/MarketingInfoService';
import { ThunkResult } from './index';
import PhotoService from '../services/PhotoService';
import { IPhotoResponseModel } from '../models/projectPhoto';

export enum MarketingInfoActionTypes {
  SET_MARKETING_INFO = 'SET_MARKETING_INFO',
  UPDATE_MARKETING_PHOTO = 'UPDATE_MARKETING_PHOTO',
  SET_COVER_PHOTO = 'SET_COVER_PHOTO',
  SELECT_MARKETING_PHOTO = 'SELECT_MARKETING_PHOTO',
  ADD_MARKETING_PHOTO = 'ADD_MARKETING_PHOTO',
  DELETE_MARKETING_PHOTO = 'DELETE_MARKETING_PHOTO',
  CLEAR_MARKETING_INFO = 'CLEAR_MARKETING_INFO',
}

export interface IMarketingInfoAction extends Action {
  type: MarketingInfoActionTypes;
  payload?: IMarketingInfoResponseModel | IPhotoResponseModel | number | null;
}

export const setCoverPhotoAction = (payload: number): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.SET_COVER_PHOTO,
  payload,
});
export const setMarketingInfoAction = (payload: IMarketingInfoResponseModel): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.SET_MARKETING_INFO,
  payload,
});
export const clearMarketingInfoAction = (): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.CLEAR_MARKETING_INFO,
});
export const updateMarketingPhotoAction = (payload: IPhotoResponseModel): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.UPDATE_MARKETING_PHOTO,
  payload,
});

export const addMarketingPhotoAction = (payload: IPhotoResponseModel): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.ADD_MARKETING_PHOTO,
  payload,
});

export const deleteMarketingPhotoAction = (payload: number): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.DELETE_MARKETING_PHOTO,
  payload,
});

export const selectMarketingPhotoAction = (payload: IPhotoResponseModel | null): IMarketingInfoAction => ({
  type: MarketingInfoActionTypes.SELECT_MARKETING_PHOTO,
  payload,
});

export const loadMarketingInfo = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await MarketingInfoService.getByProjectId(projectId);
    dispatch(setMarketingInfoAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const saveMarketingInfo = (
  projectId: number,
  data: IMarketingInfoRequestModel,
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const result = await MarketingInfoService.save(projectId, data);
    dispatch(setMarketingInfoAction(result));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const addPhoto = (projectId: number, data: FormData, tabName: string): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const res = await MarketingInfoService.addPhoto(projectId, data, tabName);
    const photo = { ...res, tabName };
    dispatch(addMarketingPhotoAction(photo));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const addPhotos = (
  projectId: number,
  arrayOfData: FormData[],
  tabName: string,
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    arrayOfData.forEach(async x => {
      try {
        const res = await MarketingInfoService.addPhoto(projectId, x, tabName);
        const photo = { ...res, tabName };
        dispatch(addMarketingPhotoAction(photo));
      } catch (e) {
        console.log(e);
      }
    });

    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const setCoverPhoto = (fileObjectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const res = await PhotoService.setCover(fileObjectId);
    if (res) {
      dispatch(setCoverPhotoAction(fileObjectId));
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const deletePhoto = (photoId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const result = await PhotoService.deletePhoto(photoId);
    if (result) {
      dispatch(deleteMarketingPhotoAction(photoId));
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const selectPhoto = (data: IPhotoResponseModel | null): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(selectMarketingPhotoAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const clearMarketingInfo = (): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    dispatch(clearMarketingInfoAction());
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
