import { Action, Dispatch } from 'redux';
import { IProjectFinancialResponseModel } from '../models/financial';
import FinancialService from '../services/FinancialService';
import { ThunkResult } from './index';
import { IVersionResponse } from '../models';

export enum FinancialInfoActionTypes {
  SET_FINANCIAL_INFO = 'SET_FINANCIAL_INFO',
  CLEAR__FINANCIAL_INFO = 'CLEAR__FINANCIAL_INFO',
}

export interface IFinancialInfoAction extends Action {
  type: FinancialInfoActionTypes;
  payload?: IVersionResponse<IProjectFinancialResponseModel>;
}

export const cleatAction = (): IFinancialInfoAction => ({
  type: FinancialInfoActionTypes.CLEAR__FINANCIAL_INFO,
});
export const clearFinancialInfo = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const setFinancialInfoAction = (
  payload: IVersionResponse<IProjectFinancialResponseModel>,
): IFinancialInfoAction => ({ type: FinancialInfoActionTypes.SET_FINANCIAL_INFO, payload });
export const loadFinancialInfo = (projectId: number, versionId?: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await FinancialService.getByProjectId(projectId, versionId);
    dispatch(setFinancialInfoAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
