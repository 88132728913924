import { Action } from 'redux';
import { ThunkResult } from '.';

export enum LoadingActionTypes {
  ADD = 'LOADING_ADD',
  CLEAR = 'LOADING_CLEAR',
  REMOVE = 'LOADING_REMOVE',
}
export interface ILoadingAction extends Action {
  type: LoadingActionTypes;
  payload?: boolean;
}
export const removeLoading = (): ThunkResult<any> => dispatch => {
  dispatch({ type: LoadingActionTypes.REMOVE });
};
export const addLoading = (): ThunkResult<any> => dispatch => {
  dispatch({ type: LoadingActionTypes.ADD });
};
export const clearLoading = (): ThunkResult<any> => dispatch => {
  dispatch({ type: LoadingActionTypes.CLEAR });
};
