import { Action, Dispatch } from 'redux';
import { IEventResponseModel, ICreateEventRequestModel, IEventHistoryItem } from '../models/event';
import { ThunkResult } from './index';
import EventService from '../services/EventService';
import { IRootState } from '../reducers';
import { setEventsAction } from './MilestoneAction';

export enum EventActionTypes {
  SET_SELECTED_EVENT = 'SET_SELECTED_EVENT',
  CLEAR_SELECTED_EVENT = 'CLEAR_SELECTED_EVENT',
  SET_EVENT_HISTORY_LOADING = 'SET_EVENT_HISTORY_LOADING',
  SET_SELECTED_EVENT_HISTORY = 'SET_SELECTED_EVENT_HISTORY',
  CLEAR_SELECTED_EVENT_HISTORY = 'CLEAR_SELECTED_EVENT_HISTORY',
  SET_ADDING = 'SET_ADDING',
  SET_ADDING_ERROR = 'SET_ADDING_ERROR',
  CLEAR_ADDING_ERROR = 'CLEAR_ADDING_ERROR',
  CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS',
}

export interface IEventAction extends Action {
  type: EventActionTypes;
  payload?: boolean | string | IEventResponseModel | IEventHistoryItem[];
}

export const cleatAction = (): IEventAction => ({
  type: EventActionTypes.CLEAR_ALL_EVENTS,
});
export const clearEvents = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const setSelectedEventAction = (payload: IEventResponseModel): IEventAction => ({
  type: EventActionTypes.SET_SELECTED_EVENT,
  payload,
});

export const clearSelectedEventAction = (): IEventAction => ({
  type: EventActionTypes.CLEAR_SELECTED_EVENT,
});

export const setEventHistoryLoadingAction = (payload: boolean): IEventAction => ({
  type: EventActionTypes.SET_EVENT_HISTORY_LOADING,
  payload,
});

export const setSelectedEventHistoryAction = (payload: IEventHistoryItem[]): IEventAction => ({
  type: EventActionTypes.SET_SELECTED_EVENT_HISTORY,
  payload,
});

export const clearSelectedEventHistoryAction = (): IEventAction => ({
  type: EventActionTypes.CLEAR_SELECTED_EVENT_HISTORY,
});

export const setAddingAction = (payload: boolean): IEventAction => ({
  type: EventActionTypes.SET_ADDING,
  payload,
});

export const setAddingErrorAction = (payload: string): IEventAction => ({
  type: EventActionTypes.SET_ADDING_ERROR,
  payload,
});

export const clearAddingErrorAction = (): IEventAction => ({
  type: EventActionTypes.CLEAR_ADDING_ERROR,
});

export const selectEvent = (event: IEventResponseModel): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(setSelectedEventAction(event));
};

export const clearSelectedEvent = (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(clearSelectedEventAction());
};

export const loadEventHistory = (projectId: number, eventId: number): ((dispatch: Dispatch) => void) => async (
  dispatch: Dispatch,
) => {
  dispatch(setEventHistoryLoadingAction(true));

  try {
    const data = await EventService.getEventHistory(projectId, eventId);

    dispatch(setSelectedEventHistoryAction(data));
    dispatch(setEventHistoryLoadingAction(false));

    return true;
  } catch (e) {
    dispatch(setEventHistoryLoadingAction(false));

    console.log(e);
  }

  return false;
};

export const clearSelectedEventHistory = (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(clearSelectedEventHistoryAction());
};

export const addEvent = (projectId: number, payload: ICreateEventRequestModel): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
  getState: () => IRootState,
) => {
  dispatch(clearAddingErrorAction());
  dispatch(setAddingAction(true));

  try {
    const data = await EventService.create(projectId, payload);

    const { events } = getState().milestones;

    dispatch(setEventsAction([...events, data]));
    dispatch(setAddingAction(false));

    return true;
  } catch (e) {
    dispatch(
      setAddingErrorAction(
        'При сохранении мероприятия произошла ошибка. Проверьте корректность заполнения полей и повторите попытку.',
      ),
    );
    dispatch(setAddingAction(false));

    console.log(e);
  }

  return false;
};

export const clearEventAddingError = (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(clearAddingErrorAction());
};
