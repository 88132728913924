import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import UserService from '../services/UserService';
import { IUserRoleResponseModel } from '../models/account';

export enum UserActionTypes {
  SET_USERS = 'SET_USERS',
  CLEAR_USERS = 'CLEAR_USERS',
  UPDATE_USER = 'UPDATE_USER',
}
export interface IUserAction extends Action {
  type: UserActionTypes;
  payload?: IUserRoleResponseModel[] | IUserRoleResponseModel;
}

export const clearUsersAction = (): IUserAction => ({
  type: UserActionTypes.CLEAR_USERS,
});

export const clearUsers = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearUsersAction());
  return true;
};

export const updateUserAction = (payload: IUserRoleResponseModel): IUserAction => ({
  type: UserActionTypes.UPDATE_USER,
  payload,
});

export const updateUser = (data: IUserRoleResponseModel): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(updateUserAction({ ...data, changed: true }));
  return true;
};

export const setUsersAction = (payload: IUserRoleResponseModel[]): IUserAction => ({
  type: UserActionTypes.SET_USERS,
  payload,
});

export const loadUsers = (): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await UserService.getUserRoleList();
    dispatch(setUsersAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
