import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import ParticipantService from '../services/ParticipantService';
import { IParticipantModel, IParticipantRequestModel } from '../models/participant';

export enum ParticipantActionTypes {
  SET_PARTICIPANTS = 'SET_PARTICIPANTS',
  ADD_PARTICIPANT = 'ADD_PARTICIPANT',
  UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT',
  DELETE_PARTICIPANT = 'DELETE_PARTICIPANT',
  CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS',
}
export interface IParticipantAction extends Action {
  type: ParticipantActionTypes;
  payload?: IParticipantModel[] | IParticipantModel | number;
}
export const clearParticipantsAction = (): IParticipantAction => ({
  type: ParticipantActionTypes.CLEAR_PARTICIPANTS,
});

export const clearParticipants = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearParticipantsAction());
  return true;
};

export const setParticipantsAction = (payload: IParticipantModel[]): IParticipantAction => ({
  type: ParticipantActionTypes.SET_PARTICIPANTS,
  payload,
});
export const addParticipantAction = (payload: IParticipantModel): IParticipantAction => ({
  type: ParticipantActionTypes.ADD_PARTICIPANT,
  payload,
});
export const updateParticipantAction = (payload: IParticipantModel): IParticipantAction => ({
  type: ParticipantActionTypes.UPDATE_PARTICIPANT,
  payload,
});
export const deleteParticipantAction = (payload: number): IParticipantAction => ({
  type: ParticipantActionTypes.DELETE_PARTICIPANT,
  payload,
});

export const addParticipant = (
  projectId: number,
  data: IParticipantRequestModel,
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const value = await ParticipantService.add(projectId, data);
    dispatch(addParticipantAction(value));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const updateParticipant = (
  projectId: number,
  participantId: number,
  data: IParticipantRequestModel,
): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const value = await ParticipantService.update(projectId, participantId, data);
    dispatch(updateParticipantAction(value));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const deleteParticipant = (projectId: number, id: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const value = await ParticipantService.deleteById(projectId, id);
    if (value) {
      dispatch(deleteParticipantAction(id));
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loadParticipants = (projectId: number): ThunkResult<Promise<boolean>> => async (dispatch: Dispatch) => {
  try {
    const data = await ParticipantService.getByProjectId(projectId);
    dispatch(setParticipantsAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};
