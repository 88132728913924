import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import Loadable from 'react-loadable';
import configureStore, { history } from './store';
import Loading from './components/Shared/Loading';
import { getRoute, LOGIN } from './constants/pagesNames';
import './assets/css/fonts.css';
import 'antd/dist/antd.css';
import './index.css';

const clientVersion: string = localStorage.getItem('REACT_APP_VERSION') || '';
const actualVersion: string = '2.1';

if (!clientVersion) {
  localStorage.clear();
  localStorage.setItem('REACT_APP_VERSION', actualVersion);
} else if (clientVersion !== actualVersion) {
  localStorage.clear();
  localStorage.setItem('REACT_APP_VERSION', actualVersion);
}

const initialState = {};
export const store = configureStore(initialState);
export default store;
const LoadableAuthorization = Loadable({
  loader: () => import('./components/Authorization'),
  loading: Loading,
});
const LoadableApp = Loadable({
  loader: () => import('./components/App'),
  loading: Loading,
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={getRoute(LOGIN)} component={LoadableAuthorization} />
        <Route path="/" component={LoadableApp} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
