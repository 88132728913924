import { IResponse, ServiceBase } from './ServiceBase';
import { ICreateEventRequestModel, ICreateEventResponseModel, IEventHistoryItem } from '../models/event';

export default class EventService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getEventHistory(projectId: number, eventId: number): Promise<IEventHistoryItem[]> {
    const { data } = await this.get<IResponse<IEventHistoryItem[]>>(`/${projectId}/Event/${eventId}/History`);

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }

  public static async create(projectId: number, payload: ICreateEventRequestModel): Promise<ICreateEventResponseModel> {
    const response = await this.post<IResponse<ICreateEventResponseModel>>(`/${projectId}/Event`, payload);

    // @ts-ignore
    const { data } = 'response' in response ? response.response : response;

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }
}
