import { Reducer } from 'redux';
import { IMileStoneAction, MilestoneActionTypes } from '../actions/MilestoneAction';
import { IEventResponseModel, IMilestoneHistoryItem, IMilestoneResponseModel } from '../models/milestone';
import { EventActionTypes, IEventAction } from '../actions/EventAction';
import { IEventHistoryItem } from '../models/event';

export interface IMilestoneState {
  isLoading: boolean;
  milestones: IMilestoneResponseModel[];
  selectedMilestone?: IMilestoneResponseModel;
  isLoadingMilestoneHistory: boolean;
  selectedMilestoneHistory?: IMilestoneHistoryItem[];
  events: IEventResponseModel[];
  selectedEvent?: IEventResponseModel;
  isLoadingEventHistory: boolean;
  selectedEventHistory?: IEventHistoryItem[];
  isAddingEvent: boolean;
  eventAddingError?: string;
  approvalDate: Date;
  modifiedDate: Date;
  currentVersionId?: number;
  isUpdatingMilestones: boolean;
}

const initialState: IMilestoneState = {
  isLoading: false,
  milestones: [],
  selectedMilestone: undefined,
  isLoadingMilestoneHistory: false,
  selectedMilestoneHistory: undefined,
  events: [],
  selectedEvent: undefined,
  isLoadingEventHistory: false,
  selectedEventHistory: undefined,
  isAddingEvent: false,
  eventAddingError: undefined,
  approvalDate: new Date(),
  modifiedDate: new Date(),
  currentVersionId: undefined,
  isUpdatingMilestones: false,
};

export const MilestoneReducer: Reducer<IMilestoneState, IMileStoneAction | IEventAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case MilestoneActionTypes.CLEAR_ALL_MILESTONES:
      return initialState;
    case MilestoneActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload as boolean,
      };

    case MilestoneActionTypes.SET_MILESTONES:
      return {
        ...state,
        milestones: payload as IMilestoneResponseModel[],
      };

    case MilestoneActionTypes.SET_SELECTED_MILESTONE:
      return {
        ...state,
        selectedMilestone: payload as IMilestoneResponseModel,
      };

    case MilestoneActionTypes.CLEAR_SELECTED_MILESTONE:
      return {
        ...state,
        selectedMilestone: undefined,
      };

    case MilestoneActionTypes.SET_MILESTONE_HISTORY_LOADING:
      return {
        ...state,
        isLoadingMilestoneHistory: payload as boolean,
      };

    case MilestoneActionTypes.SET_SELECTED_MILESTONE_HISTORY:
      return {
        ...state,
        selectedMilestoneHistory: payload as IMilestoneHistoryItem[],
      };

    case MilestoneActionTypes.CLEAR_SELECTED_MILESTONE_HISTORY:
      return {
        ...state,
        selectedMilestoneHistory: undefined,
      };

    case MilestoneActionTypes.SET_EVENTS:
      return {
        ...state,
        events: payload as IEventResponseModel[],
      };

    case EventActionTypes.SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: payload as IEventResponseModel,
      };

    case EventActionTypes.CLEAR_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: undefined,
      };

    case EventActionTypes.SET_EVENT_HISTORY_LOADING:
      return {
        ...state,
        isLoadingEventHistory: payload as boolean,
      };

    case EventActionTypes.SET_SELECTED_EVENT_HISTORY:
      return {
        ...state,
        selectedEventHistory: payload as IEventHistoryItem[],
      };

    case EventActionTypes.CLEAR_SELECTED_EVENT_HISTORY:
      return {
        ...state,
        selectedEventHistory: undefined,
      };

    case MilestoneActionTypes.SET_APPROVAL_DATE:
      return {
        ...state,
        approvalDate: payload as Date,
      };

    case MilestoneActionTypes.SET_MODIFIED_DATE:
      return {
        ...state,
        modifiedDate: payload as Date,
      };

    case EventActionTypes.SET_ADDING:
      return {
        ...state,
        isAddingEvent: payload as boolean,
      };

    case MilestoneActionTypes.SET_MILESTONES_VERSION:
      return {
        ...state,
        currentVersionId: payload as number,
      };

    case EventActionTypes.SET_ADDING_ERROR:
      return {
        ...state,
        eventAddingError: payload as string,
      };

    case EventActionTypes.CLEAR_ADDING_ERROR:
      return {
        ...state,
        eventAddingError: undefined,
      };

    case MilestoneActionTypes.SET_UPDATING_MILESTONES:
      return {
        ...state,
        isUpdatingMilestones: payload as boolean,
      };

    default:
      return state;
  }
};
