import { Reducer } from 'redux';
import { IUserModel } from '../models/account';
import { AuthorizationActionTypes, IAuthorizationActions } from '../actions/AuthorizationAction';

export interface IAuthorizationState {
  account: IUserModel;
  tokenJWT: string;
  errors: string[];
}

const initialState: IAuthorizationState = {
  account: {
    login: '',
    isLogedIn: false,
    fullName: '',
    roleId: 0,
    roleName: '',
    id: 0,
  },
  errors: [],
  tokenJWT: '',
};
export const AuthorizationReducer: Reducer<IAuthorizationState, IAuthorizationActions> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case AuthorizationActionTypes.LOGIN_SUCCESS:
      localStorage.setItem('AUTH_TOKEN', payload as string);
      return {
        ...state,
        tokenJWT: payload as string,
        errors: [],
      };
    case AuthorizationActionTypes.SET_ACCOUNT: {
      const usr = payload as IUserModel;
      usr.isLogedIn = true;
      return {
        ...state,
        account: usr,
      };
    }
    case AuthorizationActionTypes.LOGOUT:
      localStorage.removeItem('AUTH_TOKEN');
      return { ...initialState };

    case AuthorizationActionTypes.ADD_AUTHORIZATION_ERROR:
      return {
        ...state,
        account: initialState.account,
        errors: [payload as string],
      };
    case AuthorizationActionTypes.CLEAR_AUTHORIZATION_ERROR:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
