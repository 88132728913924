import { ServiceBase, IResponse } from './ServiceBase';
import { IVersionRequestModel, IVersionResponse } from '../models';
import { IProjectSalesResponse, IProjectSalesRequest } from '../models/sales';

export default class SalesService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number, versionId?: number): Promise<IVersionResponse<IProjectSalesResponse>> {
    let url = `/${id}/Sales`;
    if (versionId && versionId > 0) {
      url += `/?versionId=${versionId}`;
    }
    const res = await this.get<IResponse<IVersionResponse<IProjectSalesResponse>>>(url);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async save(
    projectId: number,
    data: IVersionRequestModel<IProjectSalesRequest>,
  ): Promise<IVersionResponse<IProjectSalesResponse>> {
    const res = await this.put<IResponse<IVersionResponse<IProjectSalesResponse>>>(`/${projectId}/Sales`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
