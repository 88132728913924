import { ServiceBase, IResponse } from './ServiceBase';
import { IProjectModel } from '../models/project';
import { IVersionResponse } from '../models';
import { IMilestonesResponse } from '../models/milestone';

export default class ProjectService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static loginError = 'Логин или пароль указаны неверно';

  public static async getList(): Promise<IProjectModel[]> {
    const res = await this.get<IResponse<IProjectModel[]>>('');
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async getForManager(): Promise<IProjectModel[]> {
    const res = await this.get<IResponse<IProjectModel[]>>('/ForManager');
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async getById(id: number): Promise<IProjectModel> {
    const res = await this.get<IResponse<IProjectModel>>(`/${id}`);
    if (res.data.success) return res.data.data;
    throw new Error(res.data.error);
  }

  public static async restoreVersion(
    prijectId: number,
    versionId: number,
  ): Promise<IVersionResponse<IMilestonesResponse>> {
    const res = await this.put<IResponse<IVersionResponse<IMilestonesResponse>>>(
      `/${prijectId}/RestoreVersion/${versionId}`,
    );
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
