import { IResponse, ServiceBase } from './ServiceBase';
import { IPhotoResponseModel } from '../models/projectPhoto';

export default class PhotoService extends ServiceBase {
  protected static BASE_URL = '/Photo';

  public static async getFileByObjectId(id: number): Promise<Blob> {
    const res = await this.get<Blob>(`/${id}`, undefined, { responseType: 'blob' });
    return res.data;
  }

  public static async deletePhoto(id: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async setCover(id: number): Promise<boolean> {
    const res = await this.put<IResponse<boolean>>(`/${id}/SetCover`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async addPhoto(data: FormData): Promise<IPhotoResponseModel> {
    const res = await this.post<IResponse<IPhotoResponseModel>>('/', data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
