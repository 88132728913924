import compact from 'lodash/compact';

export const LOGIN: string = 'login';
export const NOT_FOUND: string = 'not-found';
export const PROJECTS: string = 'projects';
export const PROJECT: string = 'project';
export const SETTINGS: string = 'settings';
export const PLANE: string = 'plane';

export const COMMON: string = 'common';
export const TEMPLATES: string = 'templates';
export const MILESTONES: string = 'milestones';

export const DOCUMENTS: string = 'documents';
export const LISTS: string = 'lists';
export const PHOTOS: string = 'photos';
export const TEAM: string = 'team';
export const OPTIONALINDICATOR: string = 'optional_indicator';
export const ALLINVESTMENT: string = 'all_investment';
export const CLOSEDMILESTONES: string = 'closed_milestones';
export const STATUS: string = 'status';
export const INDICATORS: string = 'indicators';
export const EDIT: string = 'edit';
export const ADD: string = 'add';
export const VERSION: string = 'version';
export const FINANCIAL: string = 'financial';
export const SALES: string = 'sales';
export const USERS: string = 'users';
export const REALIZATION_CONTROL: string = 'realization';
export const MARKETING: string = 'marketing';
export const BUILDING: string = 'building';

export const getRoute = (pages: any[] | string): string => {
  if (pages instanceof Array) {
    return `/${compact(pages).join('/')}`;
  }
  return `/${pages}`;
};
