import { ServiceBase, IResponse } from './ServiceBase';
import { IMilestoneHistoryItem, IMilestonesResponse, IUpdateProjectRequest } from '../models/milestone';
import { IVersionResponse } from '../models';

export default class MilestoneService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getByProjectId(id: number, versionId?: number): Promise<IVersionResponse<IMilestonesResponse>> {
    const query = versionId && versionId > 0 ? `/?versionId=${versionId}` : '';
    const url = `/${id}/Milestone${query}`;
    const { data } = await this.get<IResponse<IVersionResponse<IMilestonesResponse>>>(url);

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }

  public static async getMilestoneHistory(projectId: number, milestoneId: number): Promise<IMilestoneHistoryItem[]> {
    const { data } = await this.get<IResponse<IMilestoneHistoryItem[]>>(
      `/${projectId}/Milestone/${milestoneId}/History`,
    );

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }

  public static async updateMilestones(
    projectId: number,
    payload: IUpdateProjectRequest,
  ): Promise<IVersionResponse<IMilestonesResponse>> {
    const response = await this.put<IResponse<IVersionResponse<IMilestonesResponse>>>(
      `/${projectId}/Milestone`,
      payload,
    );

    // @ts-ignore
    const { data } = 'response' in response ? response.response : response;

    if (!data.success) {
      throw new Error(data.error);
    }

    return data.data;
  }
}
