import { ServiceBase, IResponse } from './ServiceBase';
import { IPhotoAlbumResponseModel, IPhotoAlbumRequestModel } from '../models/photoAlbum';
import { IPhotoResponseModel } from '../models/projectPhoto';

export default class PhotoAlbumService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async deleteAlbum(projectId: number, id: number): Promise<boolean> {
    const res = await this.delete<IResponse<boolean>>(`/${projectId}/PhotoAlbum/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getByProjectId(id: number): Promise<IPhotoAlbumResponseModel[]> {
    const res = await this.get<IResponse<IPhotoAlbumResponseModel[]>>(`/${id}/PhotoAlbum`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async add(projectId: number, data: IPhotoAlbumRequestModel): Promise<IPhotoAlbumResponseModel> {
    const res = await this.post<IResponse<IPhotoAlbumResponseModel>>(`/${projectId}/PhotoAlbum`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async addPhoto(projectId: number, albumId: number, data: FormData): Promise<IPhotoResponseModel> {
    const res = await this.post<IResponse<IPhotoResponseModel>>(`/${projectId}/PhotoAlbum/${albumId}`, data);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
