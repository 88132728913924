import { Reducer } from 'redux';
import { IMarketingInfoAction, MarketingInfoActionTypes } from '../actions/MarketingInfoAction';
import { IMarketingInfoResponseModel } from '../models/marketingInfo';
import { IPhotoResponseModel } from '../models/projectPhoto';

export interface IMarketingInfoState {
  marketingInfo: IMarketingInfoResponseModel;
  selectedPhoto?: IPhotoResponseModel;
  photos: IPhotoResponseModel[];
}

const initialState: IMarketingInfoState = {
  photos: [],
  marketingInfo: {
    mapUrl: '',
    address: '',
    photos: [],
    architect: '',
    availabilityCarLift: '',
    carSpacesRatio: 0,
    ceilingHeight: 0,
    elevatorBrand: '',
    description: '',
    developer: '',
    district: '',
    documents: [],
    generalContractor: '',
    generalDesigner: '',
    housingClass: '',
    id: 0,
    investor: '',
  },
};

export const MarketingInfoReducer: Reducer<IMarketingInfoState, IMarketingInfoAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case MarketingInfoActionTypes.SELECT_MARKETING_PHOTO:
      return {
        ...state,
        selectedPhoto: payload as IPhotoResponseModel,
      };
    case MarketingInfoActionTypes.SET_COVER_PHOTO: {
      const id = payload as number;
      return {
        ...state,
        photos: state.photos.map(x => (x.fileObjectId !== id ? { ...x, isCover: false } : { ...x, isCover: true })),
      };
    }
    case MarketingInfoActionTypes.CLEAR_MARKETING_INFO:
      return initialState;
    case MarketingInfoActionTypes.SET_MARKETING_INFO:
      return {
        ...state,
        selectedPhoto: undefined,
        marketingInfo: payload as IMarketingInfoResponseModel,
        photos: (payload as IMarketingInfoResponseModel).photos,
      };
    case MarketingInfoActionTypes.ADD_MARKETING_PHOTO:
      return {
        ...state,
        selectedPhoto: undefined,
        marketingInfo: {
          ...state.marketingInfo,
          photos: [...state.marketingInfo.photos, payload as IPhotoResponseModel],
        },
        photos: [...state.marketingInfo.photos, payload as IPhotoResponseModel],
      };
    case MarketingInfoActionTypes.DELETE_MARKETING_PHOTO:
      return {
        ...state,
        marketingInfo: {
          ...state.marketingInfo,
          photos: state.marketingInfo.photos.filter(x => x.fileObjectId !== (payload as number)),
        },
        photos: state.photos.filter(x => x.fileObjectId !== (payload as number)),
        selectedPhoto: state.photos.length > 0 ? state.photos[0] : undefined,
      };
    case MarketingInfoActionTypes.UPDATE_MARKETING_PHOTO: {
      const photo = payload as IPhotoResponseModel;
      return {
        ...state,
        photos: [...state.photos, photo],
        selectedPhoto: state.photos.length === 0 || !state.selectedPhoto ? photo : state.selectedPhoto,
      };
    }
    default:
      return state;
  }
};
