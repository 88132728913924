import { Reducer } from 'redux';
import { IPhotoAlbumAction, PhotoAlbumActionTypes } from '../actions/PhotoAlbumAction';
import { IPhotoAlbumResponseModel } from '../models/photoAlbum';
import { IPhotoResponseModel } from '../models/projectPhoto';

export interface IPhotoAlbumState {
  photoAlbums: IPhotoAlbumResponseModel[];
  selectedAlbum?: IPhotoAlbumResponseModel;
  selectedPhoto?: IPhotoResponseModel;
}

const initialState: IPhotoAlbumState = {
  photoAlbums: [],
};

export const PhotoAlbumReducer: Reducer<IPhotoAlbumState, IPhotoAlbumAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case PhotoAlbumActionTypes.CLEAR_PHOTOABUMS:
      return initialState;
    case PhotoAlbumActionTypes.DELETE_PHOTOALBUM: {
      return {
        ...state,
        selectedAlbum: undefined,
        photoAlbums: state.photoAlbums.filter(x => x.id !== (payload as number)),
      };
    }
    case PhotoAlbumActionTypes.DELETE_PHOTO: {
      return {
        ...state,
        selectedAlbum: state.selectedAlbum
          ? {
              ...state.selectedAlbum,
              photos: state.selectedAlbum.photos.filter(x => x.fileObjectId !== (payload as number)),
            }
          : state.selectedAlbum,
        photoAlbums: state.photoAlbums.map(x => ({
          ...x,
          photos: x.photos.filter(y => y.fileObjectId !== (payload as number)),
        })),
        selectedPhoto: undefined,
      };
    }
    case PhotoAlbumActionTypes.SET_PHOTOALBUMS: {
      const ar = payload as IPhotoAlbumResponseModel[];
      return {
        ...state,
        selectedAlbum: ar.length > 0 ? ar[0] : undefined,
        photoAlbums: ar,
      };
    }
    case PhotoAlbumActionTypes.SELECT_PHOTOALBUM: {
      const album = payload as IPhotoAlbumResponseModel;
      return {
        ...state,
        selectedAlbum: album,
        selectedPhoto: album && album !== null && album.photos.length > 0 ? album.photos[0] : undefined,
      };
    }
    case PhotoAlbumActionTypes.ADD_PHOTO: {
      const photo = payload as IPhotoResponseModel;
      return {
        ...state,
        photoAlbums: state.photoAlbums.map(x => (x.id === photo.albumId ? { ...x, photos: [...x.photos, photo] } : x)),
        selectedAlbum: state.selectedAlbum
          ? { ...state.selectedAlbum, photos: [...state.selectedAlbum.photos, photo] }
          : state.selectedAlbum,
      };
    }
    case PhotoAlbumActionTypes.CLEAR_SELECTION: {
      return {
        ...state,
        selectedPhoto: undefined,
      };
    }
    case PhotoAlbumActionTypes.SELECT_PHOTO: {
      return {
        ...state,
        selectedPhoto: payload as IPhotoResponseModel,
      };
    }
    case PhotoAlbumActionTypes.ADD_PHOTOALBUM:
      return {
        ...state,
        photoAlbums: [payload as IPhotoAlbumResponseModel, ...state.photoAlbums],
        selectedAlbum: payload as IPhotoAlbumResponseModel,
      };
    default:
      return state;
  }
};
