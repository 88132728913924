import { Action } from 'redux';
import { ThunkResult } from '.';
import { INotificationModel } from '../models/notification';

export enum NotificationActionTypes {
  ADD = 'NOTIFICATION_ADD',
  CLEAR = 'NOTIFICATION_CLEAR',
  REMOVE = 'NOTIFICATION_REMOVE',
}
export interface INotificationAction extends Action {
  type: NotificationActionTypes;
  payload?: INotificationModel | string;
}
export const addNotificationAction = (payload: INotificationModel): INotificationAction => ({
  type: NotificationActionTypes.ADD,
  payload,
});
export const removeNotificationAction = (payload: string): INotificationAction => ({
  type: NotificationActionTypes.REMOVE,
  payload,
});
export const clearNotificationAction = (): INotificationAction => ({
  type: NotificationActionTypes.CLEAR,  
});
export const addNotification = (data: INotificationModel): ThunkResult<any> => dispatch => {
  dispatch(addNotificationAction(data));
};
export const removeNotification = (id: string): ThunkResult<any> => dispatch => {
  dispatch(removeNotificationAction(id));
};
