import { Action, Dispatch } from 'redux';
import { IProjectSalesResponse } from '../models/sales';
import SalesService from '../services/SalesService';
import { ThunkResult } from './index';
import { IVersionResponse, IVersionRequestModel } from '../models';

export enum SalesActionTypes {
  SET_SALES = 'SET_SALES',
  CLEAR__SALES = 'CLEAR__SALES',
  SET_UPDATING = 'SET_UPDATING',
}

export interface ISalesAction extends Action {
  type: SalesActionTypes;
  payload?: IVersionResponse<IProjectSalesResponse> | boolean;
}

export const setUpdatingAction = (payload: boolean): ISalesAction => ({
  type: SalesActionTypes.SET_UPDATING,
  payload,
});

export const clearSalesAction = (): ISalesAction => ({
  type: SalesActionTypes.CLEAR__SALES,
});
export const clearSales = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(clearSalesAction());
  return true;
};
export const setSalesAction = (payload: IVersionResponse<IProjectSalesResponse>): ISalesAction => ({
  type: SalesActionTypes.SET_SALES,
  payload,
});
export const loadSales = (projectId: number, versionId?: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await SalesService.getByProjectId(projectId, versionId);
    dispatch(setSalesAction(data));
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const saveSales = (projectId: number, data: IVersionRequestModel<any>): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(setUpdatingAction(true));
    const result = await SalesService.save(projectId, data);
    if (result != null) {
      dispatch(setSalesAction(result));
    }
    dispatch(setUpdatingAction(false));
    return true;
  } catch (e) {
    console.log(e);
  }
  dispatch(setUpdatingAction(false));
  return false;
};
