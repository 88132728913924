import React from 'react';
import { Spin } from 'antd';

export default () => (
  <div className="container">
    <div
      style={{
        zIndex: 1000,
        position: 'fixed',
        width: 50,
        height: 50,
        top: '50%',
        left: '50%',
        marginTop: -25,
        marginLeft: -25,
      }}
    >
      <Spin size="large" />
    </div>
  </div>
);
