import { Reducer } from 'redux';
import { IUserAction, UserActionTypes } from '../actions/UserAction';
import { IUserRoleResponseModel } from '../models/account';

export interface IUsersState {
  users: IUserRoleResponseModel[];
}

const initialState: IUsersState = {
  users: [],
};

export const UserReducer: Reducer<IUsersState, IUserAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case UserActionTypes.UPDATE_USER: {
      const usr = payload as IUserRoleResponseModel;
      return {
        ...state,
        users: state.users.map(x => (x.userId === usr.userId ? usr : x)),
      };
    }
    case UserActionTypes.CLEAR_USERS:
      return initialState;
    case UserActionTypes.SET_USERS: {
      const users = payload as IUserRoleResponseModel[];
      const res = users.reduce((ar, x) => {
        const index = ar.findIndex(u => u.userId === x.userId);
        const res = ar;
        if (index >= 0) {
          res[index] = { ...x, changed: false };
          return res;
        }
        return [...ar, x];
      }, state.users);
      return {
        ...state,
        users: res.map(x => ({ ...x, changed: false })),
      };
    }
    default:
      return state;
  }
};
