import { Action, Dispatch } from 'redux';
import { ThunkResult } from './index';
import MilestoneService from '../services/MilestoneService';
import {
  IEventResponseModel,
  IMilestoneHistoryItem,
  IMilestoneResponseModel,
  IUpdateProjectRequest,
} from '../models/milestone';

export enum MilestoneActionTypes {
  SET_LOADING = 'SET_LOADING',
  SET_MILESTONES = 'SET_MILESTONES',
  SET_SELECTED_MILESTONE = 'SET_SELECTED_MILESTONE',
  CLEAR_SELECTED_MILESTONE = 'CLEAR_SELECTED_MILESTONE',
  SET_MILESTONE_HISTORY_LOADING = 'SET_MILESTONE_HISTORY_LOADING',
  SET_SELECTED_MILESTONE_HISTORY = 'SET_SELECTED_MILESTONE_HISTORY',
  CLEAR_SELECTED_MILESTONE_HISTORY = 'CLEAR_SELECTED_MILESTONE_HISTORY',
  SET_EVENTS = 'SET_EVENTS',
  SET_APPROVAL_DATE = 'SET_APPROVAL_DATE',
  SET_MODIFIED_DATE = 'SET_MODIFIED_DATE',
  SET_MILESTONES_VERSION = 'SET_MILESTONES_VERSION',
  SET_UPDATING_MILESTONES = 'SET_UPDATING_MILESTONES',
  CLEAR_ALL_MILESTONES = 'CLEAR_ALL_MILESTONES',
}

export interface IMileStoneAction extends Action {
  type: MilestoneActionTypes;
  payload?:
    | IMilestoneResponseModel
    | IMilestoneResponseModel[]
    | IMilestoneHistoryItem[]
    | IEventResponseModel[]
    | Date
    | boolean
    | number;
}

export const cleatAction = (): IMileStoneAction => ({
  type: MilestoneActionTypes.CLEAR_ALL_MILESTONES,
});
export const clearMilestones = (): ThunkResult<boolean> => (dispatch: Dispatch) => {
  dispatch(cleatAction());
  return true;
};
export const setLoadingAction = (payload: boolean): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_LOADING,
  payload,
});

export const setMilestoneAction = (payload: IMilestoneResponseModel[]): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_MILESTONES,
  payload,
});

export const setSelectedMilestoneAction = (payload: IMilestoneResponseModel): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_SELECTED_MILESTONE,
  payload,
});

export const clearSelectedMilestoneAction = (): IMileStoneAction => ({
  type: MilestoneActionTypes.CLEAR_SELECTED_MILESTONE,
});

export const setMilestoneHistoryLoadingAction = (payload: boolean): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_MILESTONE_HISTORY_LOADING,
  payload,
});

export const setSelectedMilestoneHistoryAction = (payload: IMilestoneHistoryItem[]): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_SELECTED_MILESTONE_HISTORY,
  payload,
});

export const clearSelectedMilestoneHistoryAction = (): IMileStoneAction => ({
  type: MilestoneActionTypes.CLEAR_SELECTED_MILESTONE_HISTORY,
});

export const setEventsAction = (payload: IEventResponseModel[]): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_EVENTS,
  payload,
});

export const setApprovalDateAction = (payload: Date | undefined): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_APPROVAL_DATE,
  payload,
});

export const setModifiedDateAction = (payload: Date | undefined): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_MODIFIED_DATE,
  payload,
});

export const setVersionAction = (payload: number): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_MILESTONES_VERSION,
  payload,
});

export const setUpdatingMilestonesAction = (payload: boolean): IMileStoneAction => ({
  type: MilestoneActionTypes.SET_UPDATING_MILESTONES,
  payload,
});

export const loadMilestones = (projectId: number, versionId?: number): ThunkResult<Promise<boolean>> => async (
  dispatch: Dispatch,
) => {
  dispatch(setLoadingAction(true));

  try {
    const { data, approvalDate, modifiedDate, id } = await MilestoneService.getByProjectId(projectId, versionId);

    if (data) {
      dispatch(setMilestoneAction(data.milestones));
      dispatch(setEventsAction(data.events));
    }

    dispatch(setApprovalDateAction(approvalDate));
    dispatch(setModifiedDateAction(modifiedDate));
    if (id) dispatch(setVersionAction(id));
    dispatch(setLoadingAction(false));

    return true;
  } catch (e) {
    dispatch(setLoadingAction(false));
    console.log(e);
  }

  return false;
};

export const selectMilestone = (milestone: IMilestoneResponseModel): ((dispatch: Dispatch) => void) => (
  dispatch: Dispatch,
) => {
  dispatch(setSelectedMilestoneAction(milestone));
};

export const clearSelectedMilestone = (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(clearSelectedMilestoneAction());
};

export const loadMilestoneHistory = (projectId: number, milestoneId: number): ((dispatch: Dispatch) => void) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMilestoneHistoryLoadingAction(true));

  try {
    const data = await MilestoneService.getMilestoneHistory(projectId, milestoneId);

    dispatch(setSelectedMilestoneHistoryAction(data));
    dispatch(setMilestoneHistoryLoadingAction(false));

    return true;
  } catch (e) {
    dispatch(setMilestoneHistoryLoadingAction(false));

    console.log(e);
  }

  return false;
};

export const clearSelectedMilestoneHistory = (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
  dispatch(clearSelectedMilestoneHistoryAction());
};

export const updateMilestones = (
  projectId: number,
  payload: IUpdateProjectRequest,
): ((dispatch: Dispatch) => Promise<boolean>) => async (dispatch: Dispatch) => {
  dispatch(setUpdatingMilestonesAction(true));

  try {
    const { data, approvalDate, modifiedDate, id } = await MilestoneService.updateMilestones(projectId, payload);

    dispatch(setMilestoneAction(data.milestones));
    dispatch(setEventsAction(data.events));
    dispatch(setApprovalDateAction(approvalDate));
    dispatch(setModifiedDateAction(modifiedDate));
    if (id) dispatch(setVersionAction(id));
    dispatch(setUpdatingMilestonesAction(false));

    return true;
  } catch (e) {
    dispatch(setUpdatingMilestonesAction(false));

    console.log(e);
  }

  return false;
};
