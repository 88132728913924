import moment from 'moment';
import { ServiceBase, IResponse } from './ServiceBase';
import { IMilestoneResponseModel } from '../models/milestone';
import { IProjectStatusResponseModel, IProjectCalendarResponseModel } from '../models/project';
import { IDocumentResponseModel } from '../models/document';
import { IPhotoResponseModel } from '../models/projectPhoto';

export default class RealizationService extends ServiceBase {
  protected static BASE_URL = '/Project';

  public static async getMilestones(id: number, date?: Date): Promise<IMilestoneResponseModel[]> {
    const query = date ? `/?date=${moment(date).format('YYYY-MM-DD')}` : '';
    const url = `/${id}/Realization/Milestones${query}`;
    const res = await this.get<IResponse<IMilestoneResponseModel[]>>(url);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getStatus(id: number, date?: Date): Promise<IProjectStatusResponseModel> {
    const query = date ? `/?date=${moment(date).format('YYYY-MM-DD')}` : '';
    const url = `/${id}/Realization/Status${query}`;

    const res = await this.get<IResponse<IProjectStatusResponseModel>>(url);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getCalendar(id: number): Promise<IProjectCalendarResponseModel> {
    const res = await this.get<IResponse<IProjectCalendarResponseModel>>(`/${id}/Realization/Calendar `);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getDocuments(id: number): Promise<IDocumentResponseModel[]> {
    const res = await this.get<IResponse<IDocumentResponseModel[]>>(`/${id}/Realization/Documents`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }

  public static async getPhotos(id: number): Promise<IPhotoResponseModel[]> {
    const res = await this.get<IResponse<IPhotoResponseModel[]>>(`/${id}/Realization/Photos`);
    if (res.data.success) {
      return res.data.data;
    }
    throw new Error(res.data.error);
  }
}
