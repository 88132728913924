import { Reducer } from 'redux';
import { ICameraAction, CameraActionTypes } from '../actions/CameraAction';
import { ICameraResponseModel } from '../models/camera';

export interface ICameraState {
  cameras: ICameraResponseModel[];
}

const initialState: ICameraState = {
  cameras: [],
};

export const CameraReducer: Reducer<ICameraState, ICameraAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case CameraActionTypes.CHANGE_STATE: {
      const cam = payload as ICameraResponseModel;
      return {
        ...state,
        cameras: state.cameras.map(x => (x.id === cam.id ? { ...x, camState: cam.camState } : x)),
      };
    }
    case CameraActionTypes.CLEAR_CAMERAS:
      return initialState;
    case CameraActionTypes.DELETE_CAMERA:
      return {
        ...state,
        cameras: state.cameras.filter(x => x.id !== (payload as number)),
      };
    case CameraActionTypes.ADD_CAMERA:
      return {
        ...state,
        cameras: [...state.cameras, payload as ICameraResponseModel],
      };
    case CameraActionTypes.SET_CAMERAS:
      return {
        ...state,
        cameras: payload as ICameraResponseModel[],
      };
    default:
      return state;
  }
};
