/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import persistState from 'redux-localstorage';
import { IRootActions } from './actions';
import createRootReducer, { IRootState } from './reducers';

export const history = createHistory();

const middleware = [thunk as ThunkMiddleware<IRootState, IRootActions>, routerMiddleware(history)];

const composeEnhancers =
  process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'UEZ Portal',
      })
    : compose;

const composedEnhancers = composeEnhancers(applyMiddleware(...middleware), persistState());

export default (preloadedState: any) =>
  createStore<IRootState, IRootActions, {}, {}>(createRootReducer(history as any), preloadedState, composedEnhancers);
