import { Reducer } from 'redux';
import { ILoadingAction, LoadingActionTypes } from '../actions/LoadingAction';

export interface ILoadingState {
  loadingsCount: number;
}

export const initialState: ILoadingState = {
  loadingsCount: 0,
};

export const LoadingReducer: Reducer<ILoadingState, ILoadingAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case LoadingActionTypes.CLEAR: {
      return {
        ...state,
        loadingsCount: 0,
      };
    }
    case LoadingActionTypes.ADD: {
      return {
        ...state,
        loadingsCount: state.loadingsCount + 1,
      };
    }
    case LoadingActionTypes.REMOVE: {
      return {
        ...state,
        loadingsCount: state.loadingsCount > 0 ? state.loadingsCount - 1 : 0,
      };
    }
    default:
      return state;
  }
};
