import { Reducer } from 'redux';
import { IRealizationAction, RealizationActionTypes } from '../actions/RealizationAction';
import { IMilestoneResponseModel } from '../models/milestone';
import { IProjectCalendarResponseModel, IProjectStatusResponseModel } from '../models/project';
import { IDocumentResponseModel } from '../models/document';
import { IPhotoResponseModel } from '../models/projectPhoto';

export interface IRealizationState {
  milestones: IMilestoneResponseModel[];
  projectCalendar: IProjectCalendarResponseModel;
  projectStatus: IProjectStatusResponseModel;
  selectedDate: Date;
  selectedFilter: number;
  documents: IDocumentResponseModel[];
  expirationFilter: string;
  photos: IPhotoResponseModel[];
}

const initialState: IRealizationState = {
  milestones: [],
  photos: [],
  documents: [],
  expirationFilter: '',
  projectCalendar: { periods: [] },
  projectStatus: {
    projectCompletionPercent: 0,
    status: '',
    usedMoneyPercent: 0,
  },
  selectedDate: new Date(),
  selectedFilter: 0,
};
export const RealizationReducer: Reducer<IRealizationState, IRealizationAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case RealizationActionTypes.CLEAR_ALL:
      return initialState;
    case RealizationActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: payload as IDocumentResponseModel[],
      };
    case RealizationActionTypes.UPDATE_PHOTO_URL: {
      const photo = payload as IPhotoResponseModel;
      return {
        ...state,
        photos: state.photos.map(x => (x.fileObjectId === photo.fileObjectId ? photo : x)),
      };
    }
    case RealizationActionTypes.SET_PHOTOS:
      return {
        ...state,
        photos: payload as IPhotoResponseModel[],
      };
    case RealizationActionTypes.SET_EXPIRATION_FILTER:
      return {
        ...state,
        expirationFilter: payload as string,
      };
    case RealizationActionTypes.SET_PROJECT_MILESTONES:
      return {
        ...state,
        milestones: payload as IMilestoneResponseModel[],
      };
    case RealizationActionTypes.SET_PROJECT_STATUS:
      return {
        ...state,
        projectStatus: payload as IProjectStatusResponseModel,
      };
    case RealizationActionTypes.SET_PROJECT_CALENDAR:
      return {
        ...state,
        projectCalendar: payload as IProjectCalendarResponseModel,
      };
    case RealizationActionTypes.SET_MILESTONE_FILTER:
      return {
        ...state,
        selectedFilter: payload as number,
      };
    case RealizationActionTypes.SET_CALENDAR_DATE:
      return {
        ...state,
        selectedDate: payload as Date,
      };
    default:
      return state;
  }
};
